<fo-toolbar>
  <ng-container leftElements>
    <fo-breadcrumb
      [lastBreadcrumbLabelOverride]="entity ? entity.name : null"
    ></fo-breadcrumb>
  </ng-container>
  <ng-container rightElements>
    <fo-action-buttons
      [actionButtons]="actions"
      (actionEvent)="onActionEvent($event)"
    ></fo-action-buttons>
  </ng-container>
</fo-toolbar>
<ng-container *ngIf="contactFormService.form$ | async as contactForm">
  <crm-container [maxSize]="'xl'">
    <div class="row mx-0">
      <div [ngClass]="entity ? 'col-md-8' : 'col-md-12'">
        <crm-form-template
          [formGroup]="contactForm"
          [formFields]="infoTabFields"
        ></crm-form-template>

        <button
          type="button"
          mat-flat-button
          color="primary"
          class="button-fix"
          (click)="onSave()"
          [disabled]="preview"
        >
          {{ 'forms:' + 'save' | i18nextCap }}
        </button>
      </div>
      <div class="col-md-4" *ngIf="entity">
        <crm-history
          *ngIf="history$"
          [history$]="$any(history$)"
          (openActivityEvent)="onOpenActivity($event)"
          (openTicketEvent)="onOpenTicket($any($event))"
        ></crm-history>
      </div>
    </div>
    <footer></footer>
  </crm-container>
</ng-container>
