import { Injectable } from '@angular/core';
import { SnackbarService } from '@fullyops/shared/services/snackbar.service';
import { RequestFormType } from './ticket-request-form.component';

const fieldTabNumber = {
  name: 0,
  supportTicket: 0,
  images: 0,
  description: 0,
  parts: 1,
  uncataloguedParts: 2,
  supplier: 3,
  supplierAddress: 3,
  supplierPostCode: 3,
  supplierCity: 3,
  supplierCountry: 3,
  supplierContacts: 3,
  supplierDistance: 3,
};

@Injectable()
export class ValidationsRequestForm {
  constructor(public snackbarService: SnackbarService) {}

  validate(params: { form: RequestFormType; selectedTab: number[] }) {
    const { form, selectedTab } = params;

    form.markAllAsTouched();
    if (!form.valid) {
      this.snackbarService.openErrorFormMissingFields();

      this.openTabByFirstFieldInvalid({
        form,
        selectedTab,
      });
      return false;
    }
  }

  openTabByFirstFieldInvalid(params: {
    form: RequestFormType;
    selectedTab: number[];
  }) {
    const { form, selectedTab } = params;
    const formFields = Object.keys(fieldTabNumber);
    const firstInvalidField = formFields.find(
      (field: keyof RequestFormType['controls']) => {
        return !form.controls[field].valid;
      }
    );

    const lastTabNumber = selectedTab[selectedTab.length - 1];
    const tabToMove = fieldTabNumber[firstInvalidField];

    if (lastTabNumber != tabToMove) selectedTab.push(tabToMove);
  }
}
