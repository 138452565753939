import { Component, Inject, InjectionToken, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { I18NextPipe } from 'angular-i18next';
import { HotToastService } from '@ngneat/hot-toast';
import {
  AbstractControl,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { WorkPeriodResponse } from '@fullyops/legacy/data/api/types/Work';
import { RoleGroup, UiAuthService } from '@fullyops/legacy/ui';
import { FormTemplateFields } from '@fullyops/legacy/ui/ui-shared/form-template/form-template.component';
import { COLORS } from '@fullyops/legacy/ui/ui-shared/utils/styles';
import { TimeZoneDateFormatterService } from '@fullyops/shared/services/date-formatter.service';

interface Data {
  assignees$: BehaviorSubject<{ id: string; name: string }[]>;
  defaultWorkingPeriodDuration$: BehaviorSubject<number>;
  onSave: (values: object) => void;
  onCancel: () => void;
  onDelete: () => void;
}

export const NEW_WORKING_PERIOD_TOKEN = new InjectionToken<Data>('data');

@Component({
  selector: 'crm-new-working-period-form',
  templateUrl: './new-working-period-form.component.html',
  styleUrls: ['./new-working-period-form.component.scss'],
})
export class NewWorkingPeriodFormComponent implements OnInit {
  constructor(
    private snackBarService: HotToastService,
    private i18nextPipe: I18NextPipe,
    protected authService: UiAuthService,
    public timezoneDate: TimeZoneDateFormatterService,
    @Inject(NEW_WORKING_PERIOD_TOKEN) private data: Data
  ) {}

  public readonly isDeletable = false;
  public readonly title = 'addWorkingPeriod';

  newDate = (props: { minutes: number }) => {
    const date = this.timezoneDate.currentDate();
    const next15Minutes = 15 * Math.ceil(date.minute() / 15);
    date.minutes(props.minutes ? next15Minutes + props.minutes : next15Minutes);
    date.seconds(0);
    return date.toDate();
  };

  newWorkPeriodForm: UntypedFormGroup;
  formFields: FormTemplateFields<FormGroup>[];

  async ngOnInit() {
    this.initForm();
    this.initFormFields();
  }

  initForm() {
    const assignees = [];
    const startingHour = this.newDate({ minutes: 0 });
    const endingHour = this.newDate({
      minutes: this.data.defaultWorkingPeriodDuration$.value || 30,
    });

    this.newWorkPeriodForm = new UntypedFormGroup({
      assignees: new UntypedFormControl(assignees, Validators.required),
      startingHour: new UntypedFormControl(startingHour, Validators.required),
      endingHour: new UntypedFormControl(endingHour, [
        Validators.required,
        //this.validationEndDate.bind(this),
      ]),
    });

    this.data.defaultWorkingPeriodDuration$.subscribe((value) =>
      this.newWorkPeriodForm.controls['endingHour'].setValue(
        this.newDate({ minutes: value })
      )
    );

    const isUserTechnician = this.authService.hasRole(RoleGroup.TECHNICIAN);

    if (isUserTechnician) {
      this.newWorkPeriodForm.controls['assignees'].setValue([
        this.authService.currentUser$.value.id,
      ]);
    }
  }

  isLessThanOneDay = (d: Date | null): boolean => {
    const startDate = d || this.timezoneDate.currentDate();
    const endDate = this.timezoneDate.formatInTimezone(
      this.newWorkPeriodForm.controls['startingHour'].value
    );
    const diffTime = startDate.valueOf() - endDate.valueOf();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= 1 && diffDays >= 0;
  };

  validationEndDate(control: AbstractControl): ValidationErrors | null {
    if (!this.isLessThanOneDay) return;
    const endDate = this.timezoneDate.formatInTimezone(control.value).toDate();
    const isLessThanOneDayResult = this.isLessThanOneDay(endDate);
    if (isLessThanOneDayResult) return;
    return { message: 'itCannotBeLongerThanOneDay' };
  }

  initFormFields() {
    this.formFields = [
      {
        name: 'startingHour',
        type: 'fulldate',
        label: 'startDate',
        size: 12,
      },
      {
        name: 'endingHour',
        type: 'fulldate',
        label: 'endDate',
        size: 12,
        fullDate: { matDatepickerFilter: this.isLessThanOneDay },
      },
      {
        name: 'assignees',
        type: 'multiple-select',
        label: 'assignee',
        size: 12,
        items$: this.data.assignees$,
        translate: false,
        dataTestId: 'work-period-assignees-select',
      },
    ];
  }

  closeNewWorkPeriodEditor() {
    this.data.onCancel();
  }

  createWorkPeriod() {
    if (this.newWorkPeriodForm.invalid) {
      this.newWorkPeriodForm.markAllAsTouched();
      return this.snackBarService.error(
        this.i18nextPipe.transform('forms:SomeFieldIsInvalid'),
        {
          position: 'bottom-right',
          duration: 5000,
          dismissible: true,
          style: {
            color: 'white',
            backgroundColor: COLORS.red,
          },
          icon: '⚠️',
        }
      );
    }

    if (
      this.newWorkPeriodForm.controls['startingHour'].value >=
      this.newWorkPeriodForm.controls['endingHour'].value
    ) {
      return this.snackBarService.error(
        this.i18nextPipe.transform('forms:workStartDateGreaterThanWorkEndDate'),
        {
          position: 'bottom-right',
          duration: 5000,
          dismissible: true,
          style: {
            color: 'white',
            backgroundColor: COLORS.red,
          },
          icon: '⚠️',
        }
      );
    }

    this.data.onSave({
      endingHour: this.timezoneDate
        .formatInTimezone(this.newWorkPeriodForm.value.endingHour)
        .toISOString(),
      startingHour: this.timezoneDate
        .formatInTimezone(this.newWorkPeriodForm.value.startingHour)
        .toISOString(),
      assignees: this.newWorkPeriodForm.value.assignees,
    });
  }
}
