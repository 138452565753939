import { ContactControllerV2 } from './../ui-contact-controller-v2.service';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  FormControl,
} from '@angular/forms';
import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  Output,
  ChangeDetectorRef,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import {
  FieldType,
  PermissionType,
} from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import { CompanyFormService } from './company-form.service';
import { Location } from '@angular/common';
import {
  Company,
  UnsavedCompany,
  Response,
  TicketType,
  User,
  Contact,
} from '@fullyops/legacy/data';
import { FormComponent } from '@fullyops/legacy/ui/ui-shared/utils/form.component';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UiAuthService, DialogComponent } from '@fullyops/legacy/ui';
import { SnackbarService } from '@fullyops/shared/services/snackbar.service';
import { MatDialog } from '@angular/material/dialog';
import { TemplateFormFields } from '@fullyops/legacy/ui/ui-shared/form-template/form-template.component';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ContactRequest,
  ContactResponse,
} from '@fullyops/legacy/data/api/types/Contact';
import { ClientEquipmentControllerV2 } from '../ui-client-equipment-controller-v2.service';
import { ClientEquipmentResponse } from '@fullyops/legacy/data/api/types/ClientEquipment';
import {
  ClientEquipmentForm,
  CompanyClientEquipmentAccordion,
} from '../company-client-equipment-accordion/company-client-equipment-accordion.component';
import { CompanyControllerV2 } from '../ui-company-controller-v2.service';
import { Country } from '@fullyops/legacy/data/models/v0/Country';
import { DialogService } from '@fullyops/legacy/ui/ui-shared/dialog/dialog.service';
import { CountryForm } from '@fullyops/legacy/data/models/v0/CountryForm';
import { TenantConfigurationController } from '../ui-tenant-configuration-v2-controller.service';
import { noWhitespaceValidator } from '@fullyops/legacy/data/validators/noWhitespaceValidator';

export type EditContactProps = {
  contact: ContactResponse;
  index: number;
  contactForm: UntypedFormGroup;
};

export type DeleteContactProps = { index: number; contactId: string };

@Component({
  selector: 'crm-company-form',
  templateUrl: './company-form.component.html',
  styleUrls: ['./company-form.component.scss'],
  providers: [CompanyFormService],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class CompanyFormComponent
  extends FormComponent<Company, UnsavedCompany, CompanyFormService>
  implements OnInit
{
  constructor(
    public companyFormService: CompanyFormService,
    public authService: UiAuthService,
    public snackBar: SnackbarService,
    public dialog: MatDialog,
    private location: Location,
    private router: Router,
    private contactControllerV2: ContactControllerV2,
    public clientEquipmentControllerV2: ClientEquipmentControllerV2,
    public changeDetectorRef: ChangeDetectorRef,
    protected companyControllerV2: CompanyControllerV2,
    protected route: ActivatedRoute,
    private dialogService: DialogService,
    private tenantService: TenantConfigurationController
  ) {
    super(snackBar, authService);

    this.formService = companyFormService;
    this.formEditPermissions = [PermissionType.CAN_EDIT_COMPANIES];

    this.draft$ = new BehaviorSubject<UnsavedCompany>(
      new UnsavedCompany(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        0,
        null,
        this.getInitialUser(),
        null
      )
    );
  }

  @Input() history$: BehaviorSubject<Response<History[]>>;
  @Input() users$: BehaviorSubject<User[]>;
  @Output() openActivityEvent = new EventEmitter<string>();
  @Output() openContactEvent = new EventEmitter<string>();
  @Output() openTicketEvent = new EventEmitter<{
    ticketId: string;
    ticketType: TicketType;
  }>();
  @Output() addEquipmentEvent = new EventEmitter();
  @Output() removeEquipmentEvent = new EventEmitter<string>();
  @Output() editEquipmentEvent = new EventEmitter<string>();

  @ViewChildren(CompanyClientEquipmentAccordion)
  equipmentFormComponents: QueryList<CompanyClientEquipmentAccordion>;

  equipments$ = new BehaviorSubject<ClientEquipmentResponse[]>([]);

  timeHours = new BehaviorSubject<any[]>([]);
  assignees$ = new BehaviorSubject<[]>([]);
  subscriptions: Subscription[] = [];
  allContacts$ = new BehaviorSubject<ContactResponse[]>([]);
  companyTypes$ = new BehaviorSubject<{ id: any; name: string }[]>([]);
  countries$ = new BehaviorSubject<CountryForm[]>([]);

  newContactForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [
      Validators.required,
      noWhitespaceValidator,
    ]),
    jobPosition: new UntypedFormControl(''),
    address: new UntypedFormControl(''),
    email: new UntypedFormControl('', [Validators.email]),
    phone: new UntypedFormControl(''),
    notes: new UntypedFormControl(''),
  });
  companyEmailRequired = true;
  newContactFields: TemplateFormFields<any> = [
    {
      name: 'name',
      type: 'text',
      label: 'name',
      size: 6,
    },
    {
      name: 'jobPosition',
      type: 'text',
      label: 'jobPosition',
      size: 6,
    },
    {
      name: 'address',
      type: 'text',
      label: 'address',
      size: 12,
    },
    {
      name: 'email',
      type: 'text',
      label: 'email',
      size: 12,
    },
    {
      name: 'phone',
      type: 'text',
      label: 'phone',
      size: 12,
    },
    {
      name: 'notes',
      type: 'textarea',
      label: 'notes',
      size: 12,
    },
  ];

  canViewClientEquipmentPermission = [
    PermissionType.CAN_ACCESS_CLIENT_EQUIPMENTS,
  ];

  faUser = faUser;

  canViewClientEquipment$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  canEditAnCreate = [PermissionType.CAN_EDIT_CLIENT_EQUIPMENTS];

  selectedTabIndex = new FormControl(0);

  indexExpanded = -1;

  companyButtonDisabled = false;

  ngOnInit() {
    this.subscriptions.push(
      this.users$
        .pipe(
          map((users) =>
            users.map((user: User) => ({
              id: user.id,
              name: `${user.firstName} ${user.lastName}`,
            }))
          )
        )
        .subscribe(this.assignees$)
    );
    this.subscriptions.push(
      this.tenantService
        .getConfigurationsByLabel({
          label: 'COMPANY_EMAIL_REQUIRED',
        })
        .subscribe((r) => {
          this.companyEmailRequired = r.value === 'true';
          this.initForm();
          this.initHours();
          this.initFields();
          this.loadAllContacts();
          this.loadEquipments();
          this.setCanViewClientEquipment();
          this.getTypes();
          this.getCountries();
          this.checkFormStatus();
        })
    );
  }

  getInitialUser() {
    const userRoles = this.authService.currentUser.roles.map((r) => r.name);
    if (userRoles.includes('SALESMAN')) {
      return [this.authService.currentUser.id];
    }
    return [];
  }

  checkFormStatus() {
    this.subscriptions.push(
      this.companyFormService.form$.subscribe((form) => {
        form.statusChanges.subscribe((s) => {
          this.companyButtonDisabled = s === 'INVALID';
        });
      })
    );
  }

  getCountries() {
    this.route.data.subscribe((data) => {
      this.countries$.next(
        data?.countries
          .filter((c: Country) => c.active)
          .map((c: Country) => {
            return { countryISOCode: c.isoCode, name: c.name, id: c.isoCode };
          })
      );
    });
  }

  getTypes() {
    this.companyControllerV2.getCompaniesTypes().subscribe((types) => {
      this.companyTypes$.next(
        types.map((type) => {
          return { id: type, name: type };
        })
      );
    });
  }

  setClientEquipmentIndexOpened(index: number) {
    this.indexExpanded = index;
    this.changeDetectorRef.detectChanges();
    this.checkSomeEquipmentFormDirty();
  }

  setCanViewClientEquipment() {
    return this.authService
      .hasPermission(this.canViewClientEquipmentPermission)
      .subscribe((res) => this.canViewClientEquipment$.next(res));
  }

  loadEquipments() {
    if (!this.entity) return;
    if (!this.entity.id) return;
    this.authService
      .hasPermission(this.canViewClientEquipmentPermission)
      .subscribe((res) => {
        if (res) {
          this.clientEquipmentControllerV2
            .getAll({
              queryParameters: { companyId: this.entity.id },
            })
            .subscribe((res) => this.equipments$.next(res.results));
        }
      });
  }

  loadAllContacts() {
    if (this.entity) {
      this.contactControllerV2
        .getAll({ queryParams: { companyId: this.entity.id } })
        .subscribe((res) => {
          this.allContacts$.next(res.results);
        });
    }
  }

  parseContact(contact: Contact) {
    return {
      id: contact.id,
      columns: {
        name: { label: 'name', type: FieldType.User, value: contact.name },
        email: { label: 'email', type: FieldType.Text, value: contact.email },
        mobileNumber: {
          label: 'phone',
          type: FieldType.Text,
          value: contact.mobileNumber,
        },
        notes: {
          label: 'notes',
          type: FieldType.Text,
          value: contact.notes,
        },
      },
    };
  }

  initFields() {
    this.formFields = [
      { name: 'name', type: 'text', label: 'name', size: 8 },
      { name: 'externalId', type: 'text', label: 'externalId', size: 4 },
      {
        name: 'type',
        type: 'select',
        label: 'type',
        size: 3,
        items$: this.companyTypes$,
        nullable: false,
        translate: true,
      },
      { name: 'nif', type: 'text', label: 'nif', size: 3 },
      {
        name: 'assigneeIds',
        type: 'multiple-select',
        label: 'assignees',
        size: 6,
        items$: this.assignees$,
      },
      { name: 'address', type: 'text', label: 'address', size: 12 },
      { name: 'city', type: 'text', label: 'city', size: 4 },
      {
        name: 'countryISOCode',
        type: 'select',
        label: 'country',
        items$: this.countries$,
        size: 4,
      },
      { name: 'zipCode', type: 'text', label: 'zipcode', size: 4 },
      { name: 'distance', type: 'number', label: 'distance', size: 6 },
      {
        name: 'travelTime',
        type: 'select',
        label: 'travelTime',
        size: 6,
        items$: this.timeHours,
        nullable: false,
        translate: false,
        matTooltip: 'travelTimeMatTooltip',
      },
      { name: 'email', type: 'text', label: 'email', size: 6 },
      { name: 'phone', type: 'text', label: 'phone', size: 6 },
      { name: 'website', type: 'text', label: 'website', size: 12 },
      { name: 'notes', type: 'textarea', label: 'notes', size: 12 },
    ];
  }

  initHours() {
    this.timeHours.next(
      new Array(96).fill('', 0, 96).map((_, index) => {
        const value = Math.floor(index / 4);
        if (index % 4 === 0) {
          return { name: `${value}:00`, id: value * 60 };
        } else {
          return {
            name: `${value}:${(index % 4) * 15}`,
            id: value * 60 + (index % 4) * 15,
          };
        }
      })
    );
  }

  onActionEventV2(action, companyForm) {
    if (action == 'cancel') {
      if (!companyForm.touched) return history.back();

      if (companyForm.touched) {
        const dialogRef = this.dialog.open(DialogComponent, {
          data: {
            message: 'leavePageMessage',
            closeModalText: 'cancel',
            actionModalText: 'leave',
          },
        });
        return dialogRef
          .afterClosed()
          .pipe(take(1))
          .subscribe((res) => {
            if (res == true) {
              return this.location.back();
            }
          });
      }
    }

    if (action == 'delete') {
      this.onDelete();
    }
  }

  onOpenActivity(id: string) {
    this.openActivityEvent.emit(id);
  }

  onOpenTicket(ticket: { ticketId: string; ticketType: TicketType }) {
    this.openTicketEvent.emit(ticket);
  }

  onAddEquipment() {
    this.addEquipmentEvent.emit();
  }

  onRemoveEquipment(id: string) {
    this.removeEquipmentEvent.emit(id);
  }

  onEditEquipment(id: string) {
    this.editEquipmentEvent.emit(id);
  }

  onOpenContact(id: string) {
    this.openContactEvent.emit(id);
  }

  onSave() {
    this.subscriptions.push(
      this.companyFormService.form$.subscribe((companyForm) => {
        companyForm.markAllAsTouched();

        if (companyForm.valid) {
          this.saveEvent.emit(this.companyFormService.getDraft());
        } else if (!this.companyButtonDisabled) {
          this.snackBar.openErrorFormMissingFields();
        }
      })
    );
  }

  createContact() {
    this.newContactForm.controls['email'].markAsDirty();
    this.newContactForm.controls['email'].markAsTouched();
    if (this.newContactForm.valid) {
      const newContact: ContactRequest = {
        address: this.newContactForm.controls['address'].value,
        email: this.newContactForm.controls['email'].value,
        mobileNumber: this.newContactForm.controls['phone'].value,
        name: this.newContactForm.controls['name'].value,
        companyId: this.entity.id,
        notes: this.newContactForm.controls['notes'].value,
        jobPosition: this.newContactForm.controls['jobPosition'].value,
      };
      this.contactControllerV2.create({ newContact }).subscribe((res) => {
        this.allContacts$.next([...this.allContacts$.value, res]);
        this.newContactForm.reset();
      });
    }
  }

  redirectToContactPage(contactId: string) {
    return this.authService
      .hasPermission([PermissionType.CAN_EDIT_CONTACTS])
      .subscribe((res) => {
        if (res) {
          return this.router.navigate([`/contacts/${contactId}/edit`]);
        }
        return this.router.navigate([`/contacts/${contactId}`]);
      });
  }

  generateContactForm(contact: Contact) {
    const contactForm = new UntypedFormGroup({
      name: new UntypedFormControl(contact.name, [
        Validators.required,
        noWhitespaceValidator,
      ]),
      address: new UntypedFormControl(contact.address),
      email: new UntypedFormControl(contact.email, [Validators.email]),
      phone: new UntypedFormControl(contact.mobileNumber),
      notes: new UntypedFormControl(contact.notes),
    });

    contactForm.disable();

    return contactForm;
  }

  resetContactForm(props: { contactForm: UntypedFormGroup; index: number }) {
    const { contactForm, index } = props;
    const contact = this.allContacts$.value[index];
    contactForm.reset({
      name: contact.name,
      address: contact.address,
      email: contact.email,
      mobileNumber: contact.mobileNumber,
      notes: contact.notes,
    });
  }

  editContact(props: EditContactProps) {
    const { contact, index, contactForm } = props;
    if (contactForm.valid) {
      const newContact: ContactRequest = {
        address: contact.address,
        companyId: contact.company.id,
        email: contact.email,
        mobileNumber: contact.mobileNumber,
        name: contact.name,
        notes: contact.notes,
        jobPosition: contact.jobPosition,
      };
      this.subscriptions.push(
        this.contactControllerV2
          .updateById({ contactId: contact.id, contact: newContact })
          .subscribe(
            (contactResponse) => {
              const oldContacts = [...this.allContacts$.value];
              oldContacts[index] = contactResponse;
              this.allContacts$.next(oldContacts);
            },
            () => {
              this.resetContactForm({ contactForm, index });
            }
          )
      );
    }
  }

  deleteContact(props: DeleteContactProps) {
    const dialogRef = this.dialogService.openDialogBeforeDelete();

    dialogRef.afterClosed().subscribe((saveOutput) => {
      if (saveOutput) {
        const { contactId, index } = props;
        this.contactControllerV2.deleteById({ contactId }).subscribe((res) => {
          const newContacts = [...this.allContacts$.value];
          newContacts.splice(index, 1);
          this.allContacts$.next(newContacts);
        });
      }
    });
  }

  editEquipment(value, index) {
    const oldEquipmentData = this.equipments$.value[index];
    const newEquipmentData: ClientEquipmentResponse = {
      ...oldEquipmentData,
      ...value,
    };

    this.clientEquipmentControllerV2
      .updateById({
        clientEquipment: {
          brand: newEquipmentData.brand,
          companyId: newEquipmentData.company.id,
          equipmentData: newEquipmentData.equipmentData,
          hasWarranty: newEquipmentData.hasWarranty,
          model: newEquipmentData.model,
          serialNumber: newEquipmentData.serialNumber,
          type: newEquipmentData.type,
          warranty: newEquipmentData.warranty,
          externalId: newEquipmentData.externalId,
          name: newEquipmentData.name,
          imageIds: newEquipmentData.images.map(({ id }) => id),
        },
        clientEquipmentId: newEquipmentData.id,
      })
      .subscribe((res) => {
        const newValue = [...this.equipments$.value];
        newValue[index] = res;
        this.equipments$.next(newValue);
      });
  }

  deleteEquipment(index: number) {
    const { id } = this.equipments$.value[index];
    return this.clientEquipmentControllerV2
      .deleteById({ clientEquipmentId: id })
      .subscribe(() => {
        const newValue = [...this.equipments$.value];
        newValue.splice(index, 1);
        this.equipments$.next(newValue);
      });
  }

  createEquipment(formGroup: UntypedFormGroup) {
    const {
      brand,
      equipmentData,
      hasWarranty,
      model,
      serialNumber,
      type,
      warranty,
      externalId,
      name,
      images,
    } = formGroup.value as ClientEquipmentForm;
    this.clientEquipmentControllerV2
      .create({
        brand,
        equipmentData,
        hasWarranty,
        model,
        serialNumber,
        type,
        warranty,
        companyId: this.entity.id,
        externalId,
        name,
        imageIds: images.map(({ id }) => id),
      })
      .subscribe((res) => {
        this.resetClientEquipmentForm(formGroup);
        this.equipments$.next([...this.equipments$.value, res]);
        this.setClientEquipmentIndexOpened(-1);
      });
  }

  resetClientEquipmentForm(formGroup: UntypedFormGroup) {
    formGroup.reset({
      brand: '',
      equipmentData: '',
      model: '',
      serialNumber: '',
      hasWarranty: false,
      type: '',
      warranty: null,
    });
  }

  checkSomeEquipmentFormDirty() {
    const componentDirty = this.equipmentFormComponents.find((component) => {
      return component.formGroup.dirty == true;
    });

    if (!componentDirty) return true;

    if (
      componentDirty.currentIndex != this.indexExpanded ||
      this.selectedTabIndex.value != 2
    ) {
      const openDialog = () => {
        return this.dialog
          .open(DialogComponent, {
            data: {
              title: 'unsavedChangesTitle',
              message: 'unsavedChangesText',
              closeModalText: 'no',
              actionModalText: 'save',
              actionButtonClass: 'button-green',
              closeButtonClass: 'button-red',
            },
          })
          .afterClosed();
      };

      openDialog().subscribe((saveOutput) => {
        if (!saveOutput) {
          //reset form
          componentDirty.resetClientEquipmentForm();
          return false;
        } else {
          if (componentDirty.isNew) componentDirty.onCreate();
          if (!componentDirty.isNew) componentDirty.onEdit();
        }
      });
    }
  }

  onTabChange() {
    this.checkSomeEquipmentFormDirty();
  }
}
