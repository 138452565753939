<fo-toolbar [title]="title">
  <ng-container rightElements>
    <fo-action-buttons
      [actionButtons]="actions"
      (actionEvent)="onActionEvent($event)"
    ></fo-action-buttons>
  </ng-container>
</fo-toolbar>

<crm-container [maxSize]="'xl'">
  <ng-container *ngIf="products$ | async as products">
    <ng-container [ngSwitch]="products.state">
      <crm-error *ngSwitchCase="state.Error" [@inOut]="'in'"> </crm-error>
      <ng-container *ngSwitchCase="state.Loaded">
        <div [@inOut]="'in'" class="content-wrapper">
          <crm-search-filter
            [items$]="productsList$"
            (filtersChangeEvent)="onFiltersChange($event)"
          ></crm-search-filter>
          <crm-list-container
            [list$]="filteredItems$"
            (openEvent)="onOpen($event)"
          ></crm-list-container>
          <ng-container *ngIf="productsList$ | async as list">
            <crm-empty-warn
              *ngIf="!list.length"
              [message]="'noProducts'"
              [actionLabel]="'addNewProduct'"
              (actionEvent)="onAddNew()"
            ></crm-empty-warn>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</crm-container>
