import { CommonModule } from '@angular/common';
import { Component, Input, Pipe, PipeTransform } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { I18NextService } from 'angular-i18next';

// Internal pipe exclusively for the use of this component
@Pipe({ name: 'convertBubbles', standalone: true })
class BubblePipe implements PipeTransform {
  constructor(private i18n: I18NextService) {}

  transform(
    bubbles: Bubble[],
    maxLength: number
  ): (TextBubble | ImageBubble)[] {
    if (maxLength <= 0) {
      console.error('fo-people-bubbles requires maxLength > 0; got', maxLength);
      return [];
    }
    const orderedBubbles = [...bubbles].sort((a, b) => {
      const c = a.firstName.localeCompare(b.firstName, this.i18n.language, {
        sensitivity: 'accent',
      });
      if (c !== 0) {
        return c;
      }
      return a.lastName.localeCompare(b.lastName, this.i18n.language, {
        sensitivity: 'accent',
      });
    });
    if (orderedBubbles.length <= maxLength) {
      return orderedBubbles.map(convertBubble);
    }
    const finalBubbles = orderedBubbles
      .slice(0, maxLength - 1)
      .map(convertBubble);
    const plusText = '+' + (bubbles.length - (maxLength - 1));
    finalBubbles.push({
      kind: 'text',
      text: plusText,
      tooltip: '',
      color: bubbleColor(plusText),
    });
    return finalBubbles;
  }
}

@Component({
  selector: 'fo-people-bubbles',
  standalone: true,
  templateUrl: './template.html',
  styleUrls: ['./style.scss'],
  imports: [CommonModule, MatTooltipModule, BubblePipe],
})
export class PeopleBubblesComponent {
  @Input() bubbles: Bubble[];
  @Input() maxLength: number;
}

export interface Bubble {
  firstName: string;
  lastName: string;
  profilePictureUrl?: string;
}

function convertBubble(b: Bubble): TextBubble | ImageBubble {
  if (b.profilePictureUrl != null) {
    return {
      kind: 'image',
      imageUrl: b.profilePictureUrl,
      tooltip: b.firstName + ' ' + b.lastName,
    };
  }
  const fullName = b.firstName + ' ' + b.lastName;
  return {
    kind: 'text',
    text: b.firstName.charAt(0) + b.lastName.charAt(0),
    tooltip: fullName,
    color: bubbleColor(fullName),
  };
}

// list of colors copied from ngx-avatars, so that result is visually
// identical; see
// https://github.com/Heatmanofurioso/ngx-avatars/blob/1.5.0/projects/ngx-avatars/src/lib/avatar.service.ts
const textBubbleColors = [
  '#1abc9c',
  '#3498db',
  '#f1c40f',
  '#8e44ad',
  '#e74c3c',
  '#d35400',
  '#2c3e50',
  '#7f8c8d',
];

// more efficient implementation of the "calculateAsciiCode" algorithm
// from ngx-avatars (which actually has nothing to do with "ASCII",
// it really is just a very weak hash function)
function weakHash(s: string) {
  let h = 0;
  for (let i = 0; i < s.length; i++) {
    h += s.charCodeAt(i);
  }
  return h;
}

function bubbleColor(s: string) {
  return textBubbleColors[weakHash(s) % textBubbleColors.length];
}

interface TextBubble {
  kind: 'text';
  text: string;
  tooltip: string;
  color: string;
}

interface ImageBubble {
  kind: 'image';
  imageUrl: string;
  tooltip: string;
}
