<span class="paginator-details" *ngIf="totalItems > 0">
  {{ 'paginatorInfo' | i18nextCap: {startAt: pageSize * currentPage + 1, endAt:
  endOfCurrentPage(), total: totalItems} }}
</span>
<span class="paginator-details" *ngIf="totalItems === 0">
  {{'noResults' | i18nextCap}}
</span>
<span class="paginator-container">
  <button
    mat-icon-button
    aria-label="first page"
    [disabled]="currentPage === 0"
    (click)="goToPage(0)"
  >
    <mat-icon>first_page</mat-icon>
  </button>
  <button
    mat-icon-button
    aria-label="previous"
    [disabled]="currentPage === 0"
    (click)="goToPage(currentPage - 1)"
  >
    <mat-icon>navigate_before</mat-icon>
  </button>
  <button
    *ngFor="let i of range()"
    mat-flat-button
    class="page-button"
    [class.selected]="i === currentPage"
    (click)="goToPage(i)"
  >
    {{i + 1}}
  </button>
  <button
    mat-icon-button
    aria-label="next"
    [disabled]="currentPage === numPages() - 1"
    (click)="goToPage(currentPage + 1)"
  >
    <mat-icon>navigate_next</mat-icon>
  </button>
  <button
    mat-icon-button
    aria-label="last page"
    [disabled]="currentPage === numPages() - 1"
    (click)="goToPage(numPages() - 1)"
  >
    <mat-icon>last_page</mat-icon>
  </button>
</span>
