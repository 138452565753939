import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MaterialModule } from '@fullyops/shared/material.module';
import { I18NextModule } from 'angular-i18next';

@Component({
  standalone: true,
  imports: [MaterialModule, CommonModule, I18NextModule],
  templateUrl: './dialog.template.html',
})
export class DeleteDialogComponent {}
