import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';

import {
  faCircleXmark,
  faDownload,
  IconName,
} from '@fortawesome/free-solid-svg-icons';
import { LocaleService } from '@fullyops/shared/services/locale/locale.service';
import { SignedImageResponse } from '@fullyops/legacy/data/api/types/Image';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
export interface DialogData {
  imageUrl: string;
  title: string;
  file?: SignedImageResponse;
}

@Component({
  selector: 'crm-default-dialog',
  templateUrl: './default-dialog.component.html',
  styleUrls: ['./default-dialog.component.scss'],
})
export class DefaultDialogComponent {
  constructor(
    private localeService: LocaleService,
    public dialogRef: MatDialogRef<DefaultDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.lang = localeService.getCurrentLanguage();
  }

  faCircleXmark = faCircleXmark;
  faDownload = faDownload;

  lang: string;

  onCancel(): boolean {
    this.dialogRef.close();
    return false;
  }
  onLeave(): boolean {
    this.dialogRef.close();
    return true;
  }

  onDownload() {
    const link = this.document.createElement('a');
    link.target = '_blank';
    link.href = this.data.file.file.signedUrl;
    link.click();
    link.remove();
  }

  getFileTypeName(file: SignedImageResponse): IconName {
    const fileName = file.file.fileName.toLowerCase();
    const imageExtensions = [
      'jpg',
      'jpeg',
      'png',
      'gif',
      'bmp',
      'tif',
      'tiff',
      'svg',
      'webp',
      'raw',
      'cr2',
      'nef',
    ];

    const isImage = imageExtensions.some((ext) => fileName.endsWith(ext));
    if (isImage) return 'image';
    if (fileName.endsWith('pdf')) return 'file-pdf';
    if (fileName.endsWith('docx')) return 'file-word';
    if (fileName.endsWith('csv')) return 'file-csv';
    return 'file';
  }

  formatFileName(fileName: string) {
    return fileName.split('.')[0];
  }
}
