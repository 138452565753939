<mat-nav-list>
  <a mat-list-item (click)="openOption(WorkOrderTabsIndex.REQUESTS)">
    <span matListItemTitle>{{ 'translation:requests' | i18nextCap }}</span>
    <span matLine>{{ 'translation:requestsDescription' | i18nextCap }}</span>
  </a>

  <a mat-list-item (click)="openOption(WorkOrderTabsIndex.HISTORY)">
    <span matListItemTitle>{{ 'translation:history' | i18nextCap }}</span>
    <span matLine>{{ 'translation:historyDescription' | i18nextCap }}</span>
  </a>
</mat-nav-list>
