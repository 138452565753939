<fo-toolbar>
  <ng-container leftElements>
    <fo-breadcrumb></fo-breadcrumb>
  </ng-container>
  <ng-container rightElements>
    <fo-action-buttons
      [actionButtons]="actions"
      (actionEvent)="onActionEvent($event)"
    ></fo-action-buttons>
  </ng-container>
</fo-toolbar>
<crm-container [maxSize]="'xl'" class="content-wrapper">
  <mat-form-field
    class="example-full-width user-search-bar"
    appearance="outline"
  >
    <mat-label>{{ 'forms:search' | i18nextCap }}</mat-label>
    <input matInput [formControl]="searchUser" />
  </mat-form-field>

  <div class="users-list">
    <mat-card
      *ngFor="let user of usersV2Filtered$ | async"
      class="user-card"
      (click)="openUser(user.id)"
    >
      <div class="basic-data">
        <ngx-avatars
          [size]="36"
          [matTooltip]="user.firstName + ' ' + user.lastName"
          [name]="user.firstName + ' ' + user.lastName"
          [src]="user.profilePictureURL || ''"
          class="my-avatar"
        ></ngx-avatars>
        <span>{{ user.firstName + ' ' + user.lastName }}</span>
      </div>
      <div class="user-role-list">
        <mat-chip-set aria-label="user roles selection">
          <mat-chip
            class="role-chip"
            *ngFor="let roleName of getUserSortedRoles(user)"
            [ngStyle]="{ 'background-color': getRoleColor(roleName) }"
          >
            <div matChipAvatar class="div-role-icon">
              <fa-icon
                [icon]="getRoleIcon(roleName)"
                class="fa-role-icon"
              ></fa-icon>
            </div>
            <span>{{ roleName | i18nextCap }}</span>
          </mat-chip>
        </mat-chip-set>
      </div>
    </mat-card>
  </div>
</crm-container>
