<mat-table
  id="matTable"
  [dataSource]="
    paginatedList$?.value?.count > 0
      ? paginatedList$.value.results
      : list$
      ? list$
      : emptyData
  "
>
  <ng-content></ng-content>

  <mat-header-row
    *matHeaderRowDef="displayedColumns; sticky: true"
  ></mat-header-row>

  <ng-container matColumnDef="empty-row">
    <mat-cell *matCellDef="let element">{{
      'noResults' | i18nextCap
    }}</mat-cell>
  </ng-container>

  <mat-row
    *matRowDef="let row; columns: displayedColumns"
    (click)="handleRowClick(row)"
  >
  </mat-row>
</mat-table>
<fo-paginator
  *ngIf="paginatedList$ | async as dataset"
  [totalItems]="dataset.count"
  [pageSize]="dataset.pageSize"
  [radius]="2"
  [currentPage]="dataset.currentPage"
  (currentPageChange)="handlePageEvent($event)"
></fo-paginator>
