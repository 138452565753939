import { Component, OnInit, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ClientEquipmentControllerV2 } from './../ui-client-equipment-controller-v2.service';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TemplateFormFields } from '@fullyops/legacy/ui/ui-shared/form-template/form-template.component';
import { I18NextPipe } from 'angular-i18next';
import { ClientEquipmentResponse } from '@fullyops/legacy/data/api/types/ClientEquipment';
import { SnackbarService } from '@fullyops/shared/services/snackbar.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

type MachineModalData = {
  clientEquipments$: BehaviorSubject<ClientEquipmentResponse[]>;
  companyId: string;
};

@Component({
  selector: 'app-machine-modal',
  templateUrl: './machine-modal.component.html',
  styleUrls: ['./machine-modal.component.scss'],
})
export class MachineModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<MachineModalComponent>,
    public snackBar: SnackbarService,
    private i18nextPipe: I18NextPipe,
    protected clientEquipmentControllerV2: ClientEquipmentControllerV2,
    @Inject(MAT_DIALOG_DATA) public data: MachineModalData
  ) {}

  newClientEquipmentForm = new UntypedFormGroup({
    brand: new UntypedFormControl(''),
    equipmentData: new UntypedFormControl('', Validators.required),
    hasWarranty: new UntypedFormControl(false),
    model: new UntypedFormControl(''),
    serialNumber: new UntypedFormControl(''),
    type: new UntypedFormControl(''),
    warranty: new UntypedFormControl(null),
    name: new UntypedFormControl('', Validators.required),
    externalId: new UntypedFormControl(''),
  });

  formFields: TemplateFormFields<any> = [];

  ngOnInit() {
    this.initFormFields();
  }

  initFormFields() {
    this.formFields = [
      { name: 'name', type: 'text', label: 'name', size: 12 },
      { name: 'externalId', type: 'text', label: 'externalId', size: 12 },
      { name: 'brand', type: 'text', label: 'brand', size: 12 },
      { name: 'model', type: 'text', label: 'model', size: 12 },
      { name: 'serialNumber', type: 'text', label: 'serialNumber', size: 12 },
      { name: 'type', type: 'text', label: 'type', size: 12 },
      {
        name: 'equipmentData',
        type: 'textarea',
        label: 'equipmentData',
        size: 12,
      },
      {
        name: 'hasWarranty',
        label: 'hasWarranty',
        type: 'checkbox',
        size: 3,
      },
      { name: 'warranty', label: 'warranty', type: 'date', size: 9 },
    ];
  }

  createMachine() {
    if (this.newClientEquipmentForm.invalid) {
      this.newClientEquipmentForm.markAllAsTouched();
      return this.snackBar.openErrorFormMissingFields();
    }

    this.clientEquipmentControllerV2
      .create({
        brand: this.newClientEquipmentForm.value.brand,
        companyId: this.data.companyId,
        equipmentData: this.newClientEquipmentForm.value.equipmentData,
        hasWarranty: this.newClientEquipmentForm.value.hasWarranty,
        model: this.newClientEquipmentForm.value.model,
        serialNumber: this.newClientEquipmentForm.value.serialNumber,
        type: this.newClientEquipmentForm.value.type,
        warranty: this.newClientEquipmentForm.value.warranty,
        externalId: this.newClientEquipmentForm.value.externalId,
        name: this.newClientEquipmentForm.value.name,
        imageIds: [],
      })
      .subscribe(
        (res) => {
          const newData = [...this.data.clientEquipments$.value];
          newData.push(res);
          this.data.clientEquipments$.next(newData);
          this.dialogRef.close(res);
        },
        () => {}
      );
  }

  cancel() {
    this.dialogRef.close();
  }
}
