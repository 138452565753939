<div class="autocomplete-div-field">
  <mat-form-field class="d-block f-right full-width" appearance="outline">
    <mat-label>{{ 'forms:' + field.label | i18nextCap }}</mat-label>
    <input
      type="text"
      matInput
      [formControl]="$any(this.formGroup.controls[this.field.name])"
      [matAutocomplete]="auto"
      [name]="field.name"
      [placeholder]="field.placeholder ? (field.placeholder | i18nextCap) : ''"
      [attr.data-testid]="field.dataTestId"
      (focusout)="checkValueOnFocusOut()"
      (keydown.enter)="onEnter($event)"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayFn.bind(this)"
      (optionSelected)="onSelected($event)"
    >
      <ng-container *ngIf="this.field.autocomplete">
        <mat-option
          *ngIf="this.field.autocomplete.firstOption"
          [value]="this.field.autocomplete.firstOption.value || null"
        >
          <div class="custom-first-option">
            <div
              class="fa-icon-div"
              [ngClass]="
                this.field.autocomplete.firstOption.iconColor
                  ? this.field.autocomplete.firstOption.iconColor + '-icon'
                  : ''
              "
            >
              <fa-icon
                *ngIf="this.field.autocomplete.firstOption.fontAwesomeIcon"
                [icon]="this.field.autocomplete.firstOption.fontAwesomeIcon"
                size="sm"
              ></fa-icon>
            </div>
            {{
              'forms:' + this.field.autocomplete.firstOption.label | i18nextCap
            }}
          </div>
        </mat-option>
      </ng-container>
      <mat-option
        *ngFor="let item of filteredOptions | async"
        [value]="
          field.autocomplete?.value ? field.autocomplete?.value(item) : item
        "
      >
        <mat-icon *ngIf="item.icon">{{ item.icon }}</mat-icon>
        <ng-container
          *ngIf="
            field.translate &&
            (field.displayValue ? field.displayValue(item) : item.name)
          "
        >
          {{
            'forms:' +
              (field.displayValue ? field.displayValue(item) : item.name)
              | i18nextCap
          }}
        </ng-container>
        <ng-container
          *ngIf="
            !field.translate &&
            (field.displayValue ? field.displayValue(item) : item.name)
          "
        >
          {{ field.displayValue ? field.displayValue(item) : item.name }}
        </ng-container>
      </mat-option>
    </mat-autocomplete>

    <mat-error
      *ngIf="
        this.formGroup.controls[this.field.name].hasError('required');
        else noFound
      "
      >{{ 'validation:youMustEnterAValue' | i18nextCap }}</mat-error
    >
    <ng-template #noFound>
      <mat-error
        *ngIf="this.formGroup.controls[this.field.name].hasError('noFound')"
        >{{ 'validation:selectItemThatExistsInList' | i18nextCap }}</mat-error
      >
    </ng-template>
  </mat-form-field>
  <button
    *ngIf="field.button"
    mat-icon-button
    (click)="this.field.button.onClick()"
    color="primary"
    type="button"
    [disabled]="
      this.field.button.disabled$
        ? (this.field.button.disabled$ | async)
        : undefined
    "
  >
    <mat-icon *ngIf="field.button.iconName">
      {{ field.button.iconName }}
    </mat-icon>
    <div class="fa-icon-div" *ngIf="field.button.fontAwesomeIconName">
      <fa-icon [icon]="field.button.fontAwesomeIconName" size="lg"></fa-icon>
    </div>
  </button>
</div>
