<fo-toolbar [title]="entity ? entity.name : 'newProduct'">
  <ng-container rightElements>
    <fo-action-buttons
      [actionButtons]="actions"
      (actionEvent)="onActionEvent($event)"
    ></fo-action-buttons>
  </ng-container>
</fo-toolbar>

<ng-container *ngIf="productsFormService.form$ | async as productForm">
  <crm-container [maxSize]="'xl'">
    <form
      #ngForm="ngForm"
      [@inOut]="'in'"
      [formGroup]="productForm"
      (ngSubmit)="onSubmit()"
    >
      <mat-tab-group>
        <mat-tab>
          <ng-template mat-tab-label>
            <div>
              {{ 'forms:product' | i18nextCap }}
            </div>
          </ng-template>
          <div class="form-row">
            <div class="col-md-6">
              <crm-carousel
                [uploadService]="uploadService"
                [imagesControl]="urlsControl"
              ></crm-carousel>
            </div>
            <div class="col-md-6">
              <crm-form-template
                [uploadService]="uploadService"
                [formGroup]="productForm"
                [formFields]="formFields"
              ></crm-form-template>
            </div>
          </div>
        </mat-tab>

        <!-- subProducts -->

        <mat-tab [disabled]="!entity">
          <ng-template mat-tab-label>
            <div
              matTooltip="{{ 'youNeedToCreateProductBefore' | i18nextCap }}"
              [matTooltipDisabled]="$any(entity)"
            >
              {{ 'forms:subProducts' | i18nextCap }}
            </div>
          </ng-template>
          <ng-container *ngIf="subproducts$ | async as subproducts">
            <crm-sub-products
              [subProducts]="subproducts"
              (newSupProductEvent)="newSubProduct()"
              (editSupProductEvent)="editSubProduct($event)"
              (deleteSupProductEvent)="deleteSubProduct($event)"
              (editVariantEvent)="editVariant($event)"
            ></crm-sub-products>
          </ng-container>
        </mat-tab>

        <mat-tab [disabled]="!entity">
          <ng-template mat-tab-label>
            <div
              matTooltip="{{ 'youNeedToCreateProductBefore' | i18nextCap }}"
              [matTooltipDisabled]="$any(entity)"
            >
              {{ 'forms:additionalInformation' | i18nextCap }}
            </div>
          </ng-template>
          <crm-product-form-details
            [preview]="preview"
            title="additionalInformation"
            subtitle="reportSections"
            createEntryLabel="addSection"
            [sectionsForm]="$any(productForm.controls.additionalInfo)"
            [uploadService]="uploadService"
          ></crm-product-form-details>
        </mat-tab>
        <mat-tab [disabled]="!entity">
          <ng-template mat-tab-label>
            <div
              matTooltip="{{ 'youNeedToCreateProductBefore' | i18nextCap }}"
              [matTooltipDisabled]="$any(entity)"
            >
              {{ 'forms:technicalInformation' | i18nextCap }}
            </div>
          </ng-template>
          <crm-product-form-technical-info
            [preview]="preview"
            title="technicalInformation"
            subtitle="reportSections"
            createEntryLabel="addSection"
            [technicalInfoForm]="$any(productForm.controls.technicalInfo)"
          ></crm-product-form-technical-info>
        </mat-tab>
      </mat-tab-group>
      <div class="buttons-row">
        <div *ngIf="entity">
          <button
            class="primary clone"
            mat-flat-button
            type="button"
            (click)="cloneProduct()"
          >
            {{ 'forms:cloneProduct' | i18nextCap }}
          </button>
        </div>

        <button mat-flat-button type="submit" class="primary clone">
          {{ 'forms:save' | i18nextCap }}
        </button>
      </div>
    </form>
  </crm-container>
</ng-container>
