<div
  *ngIf="ticket$ | async as ticket"
  class="detailed-list-container"
  [ngClass]="{ 'no-travels': ticket.travels.length <= 0 }"
>
  <ng-container *ngIf="ticket.travels.length <= 0">
    <p>{{ 'noTripsRecorded' | i18nextCap }}</p>
  </ng-container>
  <div
    class="detailed-list-item"
    *ngFor="let travel of ticket.travels; let i = index"
  >
    <div class="detailed-list-item-details">
      <mat-icon>mode_of_travel</mat-icon>
      <div class="info">
        <span class="title mat-body-1 color-grey-600">{{
          travel.startingHour | date: 'd MMMM y'
        }}</span>
        <div class="subtitle">
          <crm-assignees-icon-list
            [assignees]="parseAssignees(travel)"
            [size]="18"
            [maxDisplay]="4"
          ></crm-assignees-icon-list>
          <span class="color-grey-400">
            <mat-icon class="color-grey-500">straighten</mat-icon>
            {{ travel.distance }} km
          </span>
          <span class="color-grey-400">
            <mat-icon class="color-grey-500">schedule</mat-icon>
            {{ formatTimeDiffToString(travel.startingHour, travel.endingHour) }}
          </span>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <button
        mat-icon-button
        type="button"
        class="color-grey-600"
        (click)="openForm(travel)"
      >
        <mat-icon>open_in_new</mat-icon>
      </button>
    </div>
  </div>
</div>
<div class="add-period-container">
  <button mat-stroked-button type="button" color="primary" (click)="openForm()">
    <mat-icon>assignment_add</mat-icon>
    {{ 'add' | i18nextCap }}
  </button>
</div>
