<mat-toolbar class="toolbar-container">
  <mat-toolbar-row class="toolbar-row">
    <div class="menu-left-container">
      <div class="title-container" *ngIf="title">
        {{ title | i18nextCap }}
      </div>
      <ng-content select="[leftElements]"></ng-content>
    </div>
    <div class="menu-center-container">
      <ng-content select="[centerElements]"></ng-content>
    </div>
    <div class="menu-right-container">
      <ng-content select="[rightElements]"></ng-content></div
  ></mat-toolbar-row>
</mat-toolbar>
