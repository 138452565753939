import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MaterialModule } from '@fullyops/shared/material.module';
import { I18NextModule } from 'angular-i18next';

@Component({
  selector: 'fo-paginator',
  standalone: true,
  templateUrl: './template.html',
  styleUrls: ['./style.scss'],
  imports: [I18NextModule, CommonModule, MaterialModule],
})
export class PaginatorComponent {
  @Input() totalItems: number;
  @Input() pageSize: number;
  @Input() radius: number;
  @Input() currentPage: number;
  @Output() currentPageChange: EventEmitter<number> = new EventEmitter();

  protected numPages() {
    return Math.max(1, Math.ceil(this.totalItems / this.pageSize));
  }

  protected endOfCurrentPage() {
    return Math.min(this.totalItems, (this.currentPage + 1) * this.pageSize);
  }

  protected range() {
    const n = this.numPages();
    let start = this.currentPage - this.radius;
    let end = this.currentPage + this.radius + 1;
    if (start < 0) {
      end += -start;
      start = 0;
    }
    if (end > n) {
      start -= end - n;
      end = n;
    }
    if (start < 0) {
      start = 0;
    }
    const values = Array(end - start);
    for (let i = 0; i < values.length; i++) {
      values[i] = start + i;
    }
    return values;
  }

  protected goToPage(n: number) {
    this.currentPageChange.emit(n);
  }
}
