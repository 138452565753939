import { Component, Inject, InjectionToken, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import * as moment from 'moment';
import { HotToastService } from '@ngneat/hot-toast';
import { I18NextPipe } from 'angular-i18next';
import {
  TravelRequest,
  TravelResponse,
} from '@fullyops/legacy/data/api/types/Travel';
import { RoleGroup, UiAuthService } from '@fullyops/legacy/ui';
import { FormTemplateFields } from '@fullyops/legacy/ui/ui-shared/form-template/form-template.component';
import { getFormattedDate } from '@fullyops/legacy/ui/ui-shared/utils/date-transformers';
import { COLORS } from '@fullyops/legacy/ui/ui-shared/utils/styles';
import { TimeZoneDateFormatterService } from '@fullyops/shared/services/date-formatter.service';

interface Data {
  travel?: TravelResponse;
  defaultDistance: number;
  defaultTravelTime: number;
  assignees$: BehaviorSubject<{ id: string; name: string }[]>;
  onSave: (values: object) => void;
  onCancel: () => void;
  onDelete: () => void;
}

export const NEW_TRAVEL_TOKEN = new InjectionToken<Data>('data');

@Component({
  selector: 'crm-new-travel-form',
  templateUrl: './new-travel-form.component.html',
  styleUrls: ['./new-travel-form.component.scss'],
})
export class NewTravelFormComponent implements OnInit {
  constructor(
    protected authService: UiAuthService,
    public timezoneDate: TimeZoneDateFormatterService,
    private snackBarService: HotToastService,
    private i18nextPipe: I18NextPipe,
    @Inject(NEW_TRAVEL_TOKEN) private data: Data
  ) {}

  public readonly isDeletable = !!this.data.onDelete;
  public readonly title = !!this.data.travel ? 'travelDetails' : 'addTravel';

  newDate = (props: { minutes: number }) => {
    const date = this.timezoneDate.currentDate();
    const next15Minutes = 15 * Math.ceil(date.minute() / 15);
    date.minutes(props.minutes ? next15Minutes + props.minutes : next15Minutes);
    date.seconds(0);
    return date.toDate();
  };

  form: UntypedFormGroup;
  formFields: FormTemplateFields<any>[];

  ngOnInit() {
    this.initForm();
    this.initFormFields();
  }

  initForm() {
    const assignees = this.data.travel?.assignees.map(
      (assignee) => assignee.assignee.id
    );
    const startingHour =
      this.data.travel?.startingHour ||
      getFormattedDate({
        date: this.timezoneDate.currentDate(),
      });

    const endingHour =
      this.data.travel?.endingHour ||
      getFormattedDate({
        date: this.timezoneDate.currentDate(),
        minutesShift: this.data.defaultTravelTime || 15,
      });

    this.form = new UntypedFormGroup({
      id: new UntypedFormControl(this.data.travel?.id || null),
      assignees: new UntypedFormControl(assignees || [], Validators.required),
      stay: new UntypedFormControl(!!this.data.travel?.stay, [
        Validators.required,
      ]),
      distance: new UntypedFormControl(
        this.data.travel?.distance || this.data.defaultDistance,
        Validators.required
      ),
      endingHour: new UntypedFormControl(endingHour, {
        validators: [Validators.required],
        updateOn: 'change',
      }),
      startingHour: new UntypedFormControl(startingHour, {
        validators: [Validators.required],
        updateOn: 'change',
      }),
    });

    // Add Validators
    this.form.controls['startingHour'].addValidators(() =>
      this.validateStartDate(this.form)
    );
    this.form.controls['endingHour'].addValidators(() =>
      this.validateStartDate(this.form)
    );

    this.form.controls['startingHour'].valueChanges.subscribe((value) => {
      if (!this.form.controls['stay'].value) {
        const newEndDate = moment(value).clone();
        const endDate = moment(this.form.controls['endingHour'].value);
        newEndDate.hour(endDate.hour());
        newEndDate.minute(endDate.minute());

        this.form.controls['endingHour'].setValue(newEndDate.toDate());
      }
    });

    this.form.controls['endingHour'].valueChanges.subscribe(() => {
      this.validateStartDate(this.form);
    });

    if (!this.data.travel?.stay) this.form.controls['endingHour'].disable();

    this.form.controls['stay'].valueChanges.subscribe((stay) => {
      if (stay) {
        return this.form.controls['endingHour'].enable();
      }

      this.form.controls['startingHour'].updateValueAndValidity();
      return this.form.controls['endingHour'].disable();
    });

    const isUserTechnician = this.authService.hasRole(RoleGroup.TECHNICIAN);

    if (!this.data.travel && isUserTechnician) {
      this.form.controls['assignees'].setValue([
        this.authService.currentUser$.value.id,
      ]);
    }
  }

  initFormFields() {
    this.formFields = [
      {
        name: 'startingHour',
        type: 'fulldate',
        label: 'startDate',
        size: 12,
      },
      {
        name: 'endingHour',
        type: 'fulldate',
        label: 'endDate',
        size: 12,
        fullDate: {
          hourNeverDisabled: true,
        },
      },
      {
        name: 'distance',
        type: 'number',
        label: 'distance',
        size: 6,
      },
      {
        name: 'stay',
        type: 'slide',
        label: 'stay',
        size: 6,
      },
      {
        name: 'assignees',
        type: 'multiple-select',
        label: 'assignee',
        size: 12,
        items$: this.data.assignees$,
        translate: false,
        dataTestId: 'work-period-assignees-select',
      },
    ];
  }

  validateStartDate(form: UntypedFormGroup) {
    return this.validateStartDateLogic(form) as ValidatorFn;
  }

  validateStartDateLogic(form: UntypedFormGroup): ValidationErrors | null {
    const dateGreaterError = {
      date: true,
      message: 'travelStartDateGreaterThanWorkEndDateShort',
    };

    const endHourControl = form.get('endingHour');
    const startingHourControl = form.get('startingHour');

    const startDate = moment(startingHourControl.value);
    const endDate = moment(endHourControl.value);

    if (!startDate.isValid() || !endDate.isValid()) {
      startingHourControl.setErrors(null);
      startingHourControl.markAsTouched();
      endHourControl.setErrors(null);
      endHourControl.markAsTouched();
      return null;
    }

    if (startDate.isSameOrAfter(endDate)) {
      startingHourControl.setErrors(dateGreaterError);
      startingHourControl.markAsTouched();
      endHourControl.setErrors(dateGreaterError);
      endHourControl.markAsTouched();
      return dateGreaterError;
    }

    startingHourControl.setErrors(null);
    startingHourControl.markAsTouched();
    endHourControl.setErrors(null);
    endHourControl.markAsTouched();
    return null;
  }

  onClose() {
    this.data.onCancel();
  }

  onCreate() {
    /*this.form.controls['endingHour'].setErrors({
      date: true,
      message: 'travelStartDateGreaterThanWorkEndDateShort',
    });*/

    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return this.snackBarService.error(
        this.i18nextPipe.transform('forms:SomeFieldIsInvalid'),
        {
          position: 'bottom-right',
          duration: 5000,
          dismissible: true,
          style: {
            color: 'white',
            backgroundColor: COLORS.red,
          },
          icon: '⚠️',
        }
      );
    }

    const newTravel: TravelRequest = {
      id: this.data?.travel?.id,
      supportTicketId: this.data?.travel?.supportTicketId,
      distance: this.form.controls['distance'].value,
      endingHour: this.form.controls['endingHour'].value,
      installationTicketId: this.data?.travel?.installationTicketId,
      startingHour: this.form.controls['startingHour'].value,
      stay: this.form.controls['stay'].value,
    };
    const assigneeIds = this.form.controls['assignees'].value;

    this.data.onSave({
      travel: newTravel,
      assigneesIds: assigneeIds,
    });
  }

  onDelete() {
    if (this.data.travel) this.data.onDelete();
  }
}
