import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WorkOrderScopesComponent } from './containers/list/list.component';
import { UiModule } from '@fullyops/legacy/ui/ui.module';
import { SharedModule } from '@fullyops/shared/shared.module';
import { NgxColorsModule } from 'ngx-colors';

const routes: Routes = [
  { path: '', component: WorkOrderScopesComponent, data: { breadcrumb: null } },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    UiModule,
    NgxColorsModule,
  ],
  exports: [WorkOrderScopesComponent],
  declarations: [WorkOrderScopesComponent],
})
export class WorkOrderScopesModule {}
