import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  map,
  Observable,
  ReplaySubject,
  tap,
  throwError,
} from 'rxjs';
import {
  workOrderCategories$,
  WorkOrderCategoriesRepository,
} from '@fullyops/domain/work-order-categories/repositories/work-order-categories.repository';
import {
  CreateWorkOrderCategoryModel,
  WorkOrderCategoryModel,
} from '@fullyops/domain/work-order-categories/models/work-order-category.model';
import { UuidGeneratorService } from '@fullyops/shared/services/uuid-generator.service';
import { ApiWorkOrderCategoriesAdapterService } from '@fullyops/data/work-order-categories/api-work-order-categories-adapter.service';
import { WorkOrderCategoryEntity } from '@fullyops/data/work-order-categories/entities/work-order-category-entity';

@Injectable({
  providedIn: 'root',
})
export class WorkOrderCategoriesService {
  workOrderCategories$: Observable<WorkOrderCategoryModel[]> =
    workOrderCategories$;
  activeWorkOrderCategories$: Observable<WorkOrderCategoryModel[]> =
    this.workOrderCategories$.pipe(
      map((todos) => todos.filter((it) => it.deleted === false))
    );
  workOrderCategoriesNumber$: Observable<number> =
    this.activeWorkOrderCategories$.pipe(map((todos) => todos.length));

  constructor(
    private adapter: ApiWorkOrderCategoriesAdapterService,
    private repository: WorkOrderCategoriesRepository,
    private uuidGenerator: UuidGeneratorService
  ) {}

  get(): Observable<WorkOrderCategoryModel[]> {
    const results$ = this.adapter
      .get()
      .pipe(tap((categories) => this.repository.set(categories)));

    const replaySubject = new ReplaySubject<WorkOrderCategoryModel[]>(1);
    results$.subscribe(replaySubject);

    return replaySubject;
  }

  getById(
    id: WorkOrderCategoryModel['id']
  ): Observable<WorkOrderCategoryModel> {
    const result$ = this.adapter
      .getById(id)
      .pipe(
        tap((category: WorkOrderCategoryEntity) =>
          this.repository.update(id, category)
        )
      );

    const replaySubject = new ReplaySubject<WorkOrderCategoryModel>(1);
    result$.subscribe(replaySubject);

    return replaySubject;
  }

  create(
    category: CreateWorkOrderCategoryModel
  ): Observable<WorkOrderCategoryModel> {
    const tempUuid = this.uuidGenerator.getUuid();

    this.repository.add({
      id: tempUuid,
      ...category,
      deleted: false,
    });

    const result$ = this.adapter.create(category).pipe(
      tap((category) => {
        this.repository.updateId(tempUuid, category.id);
        this.repository.update(category.id, category);
      }),
      catchError((error) => {
        this.repository.delete(tempUuid);
        return throwError(() => error);
      })
    );

    const replaySubject = new ReplaySubject<WorkOrderCategoryModel>();
    result$.subscribe(replaySubject);

    return replaySubject;
  }

  delete(id: WorkOrderCategoryModel['id']): Observable<void> {
    const category = this.repository.query(id);
    this.repository.delete(id);

    const result$ = this.adapter.delete(id).pipe(
      catchError((error) => {
        if (category) {
          this.repository.add(category);
        }
        return throwError(() => error);
      })
    );

    const replaySubject = new ReplaySubject<void>(1);
    result$.subscribe(replaySubject);

    return replaySubject;
  }

  update(category: WorkOrderCategoryModel): Observable<WorkOrderCategoryModel> {
    this.repository.update(category.id, category);

    const result$ = this.adapter.update(category).pipe(
      tap((category) => {
        this.repository.update(category.id, category);
      }),
      catchError((error) => {
        this.repository.delete(category.id);
        return throwError(() => error);
      })
    );

    const replaySubject = new ReplaySubject<WorkOrderCategoryModel>(1);
    result$.subscribe(replaySubject);

    return replaySubject;
  }

  getByLabel(
    label: WorkOrderCategoryModel['label']
  ): WorkOrderCategoryModel | null {
    return this.repository.getByLabel(label);
  }

  getByName(
    name: WorkOrderCategoryModel['name']
  ): WorkOrderCategoryModel | null {
    return this.repository.getByName(name);
  }
  getByExternalId(
    name: WorkOrderCategoryModel['externalId']
  ): WorkOrderCategoryModel | null {
    return this.repository.getByExternalId(name);
  }

  existsByLabel(label: WorkOrderCategoryModel['label']): boolean {
    return this.repository.existsByLabel(label);
  }

  existsByName(name: WorkOrderCategoryModel['name']): boolean {
    return this.repository.existsByName(name);
  }
  existsByExternalId(name: WorkOrderCategoryModel['externalId']): boolean {
    return this.repository.existsByExternalId(name);
  }
}
