import { ReportType } from './../../../data/api/types/Report';
import { ContactControllerV2 } from './../ui-contact-controller-v2.service';
import { TemplateFormFields } from './../../ui-shared/form-template/form-template.component';
import { SupportTicketContactController } from './../ui-support-ticket-contact-controller.service';
import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  Output,
  ViewChild,
  OnDestroy,
  inject,
  ElementRef,
  Renderer2,
  ChangeDetectorRef,
} from '@angular/core';
import {
  TicketSupportMultimachine,
  UnsavedTicketSupportMultimachine,
  User,
  Status,
  TicketType,
  Response,
  History,
  Part,
  UnsavedPart,
  TicketRequestMachine,
  ClientInfo,
  ClientEquipment,
} from '@fullyops/legacy/data';
import { BehaviorSubject, forkJoin, Subscription } from 'rxjs';
import { TicketSupportFormService } from './ticket-support-form.service';
import {
  PermissionType,
  Priority,
  PriorityColor,
} from '../../ui-shared/utils/crm-types';
import {
  FormControl,
  FormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { ComponentInOutAnimation } from '../../ui-shared/utils/component-base-animation';
import { map } from 'rxjs/operators';
import { FormComponent } from '../../ui-shared/utils/form.component';
import { MatDialog } from '@angular/material/dialog';
import { SupportTicketController } from '../ui-support-ticket-controller.service';
import {
  SupportTicketCategoryResponse,
  SupportTicketRequest,
  SupportTicketResponse,
} from '../../../data/api/types/SupportTicket';
import { ActivatedRoute, Router } from '@angular/router';
import { UiAuthService } from '../ui-auth.service';
import { ContactResponse } from '../../../data/api/types/Contact';
import { SnackbarService } from '@fullyops/shared/services/snackbar.service';
import { Report } from '../../../data/api/types/Report';
import { DialogComponent } from '../../ui-shared/dialog/dialog.component';
import { ReportControllerControllerV2 } from '../ui-report-controller-v2.service';
import { UserResponse } from '../../../data/api/types/User';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { WorkOrderActionsComponent } from '../work-order-action/work-order-actions/work-order-actions.component';
import { diff } from 'deep-object-diff';
import { CountryForm } from '../../../data/models/v0/CountryForm';
import { Country } from '../../../data/models/v0/Country';
import {
  faChartLine,
  faEllipsis,
  faGear,
  faInfoCircle,
  faList,
} from '@fortawesome/free-solid-svg-icons';
import { PriorityType } from '../../ui-shared/utils/types';
import { SupportTicketScopeController } from '../ui-support-ticket-scope-v2.service';
import { TicketSupportValidator } from '@fullyops/shared/services/validators/ticket-support-validator.service';
import { AssigneeService } from '@fullyops/shared/services/assignees.service';
import { WorkOrderCategoriesService } from '@fullyops/domain/work-order-categories/services/work-order-categories.service';
import { WorkOrderCategoryUtils } from '@fullyops/shared/util/work-order-categories-utils/work-order-category-utils';
import { ActionTypesService } from '@fullyops/domain/action-types/services/action-types.service';
import { SelectOptionType } from '../../ui-shared/form-select/form-select.component';
import { DialogService } from '../../ui-shared/dialog/dialog.service';
import { MomentTimezoneToAngularTimezonePipe } from '@fullyops/shared/pipes/moment-timezone-to-angular-timezone.pipe';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { MatListModule } from '@angular/material/list';
import {
  MatBottomSheet,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { DeleteDialogComponent } from '@fullyops/shared/components/delete-dialog/dialog.component';
import { KebabMenuItem } from '@fullyops/shared/components/kebab-menu/kebab-menu.component';

export type RequestPartsUncataloguedType = {
  name?: string;
};

type InfoTab = FormGroup<{
  name: UntypedFormControl;
  internalRef: UntypedFormControl;
  companyName: FormControl<SupportTicketResponse['company']['name']>;
  clientAddress: FormControl<SupportTicketResponse['company']['address']>;
  clientDistance: FormControl<SupportTicketResponse['company']['distance']>;
  clientEmail: FormControl<SupportTicketResponse['company']['email']>;
  clientPhone: FormControl<SupportTicketResponse['company']['mobileNumber']>;
  contactId: UntypedFormControl;
  malfunctionType: FormControl<SupportTicketResponse['malfunctionType']>;
  zipCode: FormControl<SupportTicketResponse['company']['zipCode']>;
  clientCity: FormControl<SupportTicketResponse['company']['city']>;
  countryISOCode: FormControl<
    SupportTicketResponse['company']['countryISOCode']
  >;
}>;

type DetailsFormGroup = FormGroup<{
  statusId: UntypedFormControl;
  priority: UntypedFormControl;
  actAt: UntypedFormControl;
  technicianIds: UntypedFormControl;
  category: FormControl<SupportTicketCategoryResponse>;
  scope: UntypedFormControl;
  interventionType: UntypedFormControl;
}>;

enum WorkOrderTabsIndex {
  INFO = 0,
  ACTIONS = 1,
  REQUESTS = 2,
  REPORTS = 3,
  WORK_LOGS = 4,
  HISTORY = 5,
}

@Component({
  selector: 'bottom-sheet-overview-example-sheet',
  templateUrl: './components/bottom-sheet-more-options-mobile.html',
})
export class BottomSheetMoreOptionsMobile {
  private _bottomSheetRef =
    inject<MatBottomSheetRef<BottomSheetMoreOptionsMobile>>(MatBottomSheetRef);

  openOption(tabIndex: WorkOrderTabsIndex): void {
    this._bottomSheetRef.dismiss(tabIndex);
  }

  protected readonly WorkOrderTabsIndex = WorkOrderTabsIndex;
}

@Component({
  selector: 'crm-ticket-support-form',
  templateUrl: './ticket-support-form.component.html',
  styleUrls: ['./ticket-support-form.component.scss'],
  providers: [TicketSupportFormService, MomentTimezoneToAngularTimezonePipe],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class TicketSupportFormComponent
  extends FormComponent<
    TicketSupportMultimachine,
    UnsavedTicketSupportMultimachine,
    TicketSupportFormService
  >
  implements OnInit, OnDestroy
{
  @ViewChild(WorkOrderActionsComponent)
  public workOrderActionsComponent: WorkOrderActionsComponent;

  // These are actually TicketSupportMultimachines on first load,
  // and actual SupportTicketResponses after updating at least once.
  // Changing the declared type has enough knock-on effects that it's
  // just not worth it.
  @Input() ticket$: BehaviorSubject<SupportTicketResponse>;
  @Input() ticket: SupportTicketResponse;
  @Input() machines$: BehaviorSubject<TicketRequestMachine[]>;
  @Input() clientEquipments$: BehaviorSubject<ClientEquipment[]>;
  @Input() usersParts$: BehaviorSubject<{ [userId: string]: Part[] }>;
  @Input() statuses$: BehaviorSubject<Status[]>;
  @Input() technician$: BehaviorSubject<User[]>;
  @Input() history$: BehaviorSubject<Response<History[]>>;
  @Input() generatingReport$: BehaviorSubject<boolean>;
  @Input() showInternalReference: boolean;

  @Output() createRequestEvent =
    new EventEmitter<UnsavedTicketSupportMultimachine>();
  @Output() openRequestEvent = new EventEmitter<string>();

  @Output() openActivityEvent = new EventEmitter<string>();
  @Output() openTicketEvent = new EventEmitter<{
    ticketId: string;
    ticketType: TicketType;
  }>();
  @Output() createPartEvent = new EventEmitter<UnsavedPart>();
  @Output() requestUserPartsEvent = new EventEmitter<string[]>();

  @Output() machineDetailsEvent = new EventEmitter<string>();
  @Output() createMachineEvent =
    new EventEmitter<UnsavedTicketSupportMultimachine>(); //new EventEmitter<UnsavedMachine>();

  @Output() machineRemoveEvent = new EventEmitter<string>();

  companyContacts$: BehaviorSubject<ContactResponse[]> = new BehaviorSubject<
    ContactResponse[]
  >([]);
  assignees$ = new BehaviorSubject<UserResponse[]>([]);
  reports$: BehaviorSubject<Report[]> = new BehaviorSubject<Report[]>([]);
  countries$ = new BehaviorSubject<CountryForm[]>([]);

  //  InfoTab
  isSubmitInfoTabDisabled$ = new BehaviorSubject(true);
  infoTabFormGroup: InfoTab = new FormGroup({
    name: new UntypedFormControl('', Validators.required),
    internalRef: new UntypedFormControl(''),
    companyName: new FormControl('', [Validators.required]),
    clientAddress: new FormControl(''),
    clientDistance: new FormControl(null),
    clientEmail: new FormControl(''),
    clientPhone: new FormControl(''),
    contactId: new UntypedFormControl(''),
    malfunctionType: new FormControl('', [Validators.required]),
    zipCode: new FormControl(''),
    clientCity: new FormControl(''),
    countryISOCode: new FormControl(''),
  });

  infoTabFormFields: TemplateFormFields<InfoTab>;
  additionalFormFields: TemplateFormFields<any>;
  formDetailsFields: TemplateFormFields<DetailsFormGroup>;
  formDetailsAssignee$ = new BehaviorSubject<{ id: string; name: string }[]>(
    []
  );

  //  Tracking Form Group
  detailsFormGroup: DetailsFormGroup = new FormGroup({
    statusId: new FormControl(''),
    priority: new FormControl('', Validators.required),
    actAt: new FormControl('', Validators.required),
    technicianIds: new UntypedFormControl([], Validators.required),
    category: new FormControl(null),
    scope: new FormControl('', Validators.required),
    interventionType: new FormControl(
      { value: '', disabled: true },
      Validators.required
    ),
  });

  // Created at form
  additionalInfoGroup = new FormGroup({
    createdAt: new FormControl({ value: '', disabled: true }, []),
    createdBy: new FormControl({ value: '', disabled: true }, []),
    lastUpdatedAt: new FormControl({ value: '', disabled: true }, []),
  });

  header: string;
  ticketId = '';
  selected: number = 0;

  requestPermissions = [
    PermissionType.CAN_EDIT_REQUEST_TICKETS,
    PermissionType.CAN_EDIT_OWN_REQUEST_TICKETS,
  ];
  reportPermissions = [PermissionType.CAN_EDIT_REPORTS];

  //  For accordion tab index, to set Accordion Tab Index Opened
  accordionOpenedIndex = -1;
  reportType: ReportType = 'SUPPORT_REPORT';

  priorities$ = new BehaviorSubject<{ id: string; name: any; color: any }[]>(
    []
  );

  private sub: Subscription;

  actionTypesOptions$ = new BehaviorSubject<SelectOptionType[]>([]);

  constructor(
    public ticketSupportMultimachineFormService: TicketSupportFormService,
    protected supportTicketController: SupportTicketController,
    public snackBar: SnackbarService,
    private supportTicketContactController: SupportTicketContactController,
    protected router: Router,
    public authService: UiAuthService,
    protected contactControllerV2: ContactControllerV2,
    public dialog: MatDialog,
    protected reportControllerControllerV2: ReportControllerControllerV2,
    protected ticketSupportValidator: TicketSupportValidator,
    protected route: ActivatedRoute,
    private assigneeService: AssigneeService,
    private actionTypesService: ActionTypesService,
    protected supportTicketScopeController: SupportTicketScopeController,
    private workOrderCategoriesService: WorkOrderCategoriesService,
    private workOrderCategoryUtils: WorkOrderCategoryUtils,
    private dialogService: DialogService,
    private datePipe: MomentTimezoneToAngularTimezonePipe,
    private ref: ChangeDetectorRef
  ) {
    super(snackBar, authService);
    this.initWorkOrderCategories();
    this.actionTypesService.getAll();
    this.sub = this.actionTypesService.actionTypes$
      .pipe(
        map((ats) =>
          ats.map((at) => ({ id: at.label, name: at.name, color: at.color }))
        )
      )
      .subscribe(this.actionTypesOptions$);
    this.formService = ticketSupportMultimachineFormService;
    this.formEditPermissions = [PermissionType.CAN_EDIT_SUPPORT_TICKETS];
    this.draft$ = new BehaviorSubject<UnsavedTicketSupportMultimachine>(
      new UnsavedTicketSupportMultimachine(
        '',
        '',
        'LOW',
        'BLOCKED',
        [''],
        '',
        [''],
        new ClientInfo('', '', '', '', ''),
        '',
        '',
        '',
        '',
        [''],
        new Date(),
        null,
        null,
        null,
        null,
        null,
        '',
        '',
        '',
        '',
        '',
        [],
        null,
        [],
        [],
        '',
        ''
      )
    );
  }

  initWorkOrderCategories() {
    this.workOrderCategoriesService.get();
  }

  initInfoFormGroupAndFields() {
    if (!this.entity) return;

    this.infoTabFormGroup.reset(
      {
        name: this.ticket$.value.name,
        internalRef: this.ticket$.value.internalRef,
        companyName: this.ticket$.value.company.name,
        clientAddress: this.ticket$.value.clientInfo.address,
        clientDistance: +this.ticket$.value.clientInfo.distance,
        clientEmail: this.ticket$.value.company.email,
        clientPhone: this.ticket$.value.company.mobileNumber,
        contactId: this.ticket$.value.contacts.map((contact) => contact.id),
        malfunctionType: this.ticket$.value.malfunctionType,
        zipCode: this.ticket$.value.clientInfo.zipCode,
        clientCity: this.ticket$.value.clientInfo.city,
        countryISOCode: this.ticket$.value.clientInfo.countryISOCode,
      },
      { emitEvent: false }
    );

    const fieldsToDisable: Array<keyof InfoTab['controls']> = [
      'companyName',
      'clientEmail',
      'clientPhone',
    ];

    fieldsToDisable.forEach((field) => {
      this.infoTabFormGroup.controls[field].disable();
    });

    if (this.preview) this.infoTabFormGroup.disable();

    this.infoTabFormGroup.markAsPristine();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  ngOnInit() {
    this.additionalFormFields = [
      { name: 'createdAt', type: 'text', label: 'createdAt', size: 12 },
      { name: 'createdBy', type: 'text', label: 'createdBy', size: 12 },
      { name: 'lastUpdatedAt', type: 'text', label: 'lastUpdatedAt', size: 12 },
    ];
    this.infoTabFormFields = [
      { name: 'name', type: 'text', label: 'title', size: 12 },
      {
        name: 'internalRef',
        type: 'text',
        label: 'internalRef',
        size: 12,
        hidden: !this.showInternalReference,
      },
      {
        name: 'companyName',
        type: 'text',
        label: 'company',
        size: 12,
        suffixIcon: 'account_balance',
        onSuffixClick: () => this.navigateToCompanyPage(),
      },
      {
        name: 'clientAddress',
        type: 'text',
        label: 'companyAddress',
        size: 9,
      },
      { name: 'clientDistance', type: 'number', label: 'distance', size: 3 },
      { name: 'clientCity', label: 'clientCity', type: 'text', size: 4 },
      {
        name: 'countryISOCode',
        type: 'select',
        label: 'country',
        items$: this.countries$,
        size: 4,
      },
      { name: 'zipCode', label: 'zipCode', type: 'text', size: 4 },

      { name: 'clientEmail', type: 'text', label: 'companyEmail', size: 8 },
      { name: 'clientPhone', type: 'text', label: 'companyPhone', size: 4 },
      {
        name: 'contactId',
        type: 'multiple-select',
        label: 'contacts',
        size: 12,
        items$: this.companyContacts$,
        nullable: false,
        translate: false,
        displayValue: (contact: ContactResponse) => {
          return `${contact.name}  ${
            contact.jobPosition ? `(${contact.jobPosition})` : ''
          }`;
        },
      },
      {
        name: 'malfunctionType',
        type: 'textarea',
        label: 'notes',
        size: 12,
      },
    ];

    this.initForm();
    this.initInfoFormGroupAndFields();
    this.loadCompanyContacts();
    this.initDetailFormGroupValues();
    this.loadReports();
    this.getCountries();
    this.disableFormIfNotEditPage();

    if (this.entity) {
      this.ticketId = this.entity.id;
    }

    if (!this.entity) {
      this.statuses$
        .pipe(
          map((statuses) => statuses.sort((a, b) => a.position - b.position))
        )
        .subscribe((statuses) => {
          if (statuses.length) {
            (this.form.get('statusId') as UntypedFormControl).patchValue(
              statuses[0].id
            );
          }
        });

      (this.form.get('statusId') as UntypedFormControl).disable();
    }

    this.ticketSupportMultimachineFormService.form$.subscribe((form) => {
      this.form = form;

      if (this.entity) {
        const ticketInfoControl = this.form.get(
          'ticketInfo'
        ) as UntypedFormControl;

        (
          this.form
            .get('ticketDetails')
            .get('companyTravelTime') as UntypedFormControl
        ).patchValue(this.entity.company.travelTime);
        (ticketInfoControl.get('clientEmail') as UntypedFormControl).patchValue(
          this.entity.company.email
        );
        (ticketInfoControl.get('clientPhone') as UntypedFormControl).patchValue(
          this.entity.company.mobileNumber
        );
      }
    });

    (this.form.get('assigneeId') as UntypedFormControl).valueChanges.subscribe(
      (assignees) => this.requestUserPartsEvent.emit(assignees)
    );

    this.onInfoTabChangeUpdateSummitAvailability();

    this.initPriorities();
    this.initAssignees();
    this.initFormDetailsFields();
    this.onFormDetailsFieldChangePatchData();
    this.ticketSubscription();
  }

  ticketSubscription() {
    this.ticket$.subscribe((ticket) => {
      this.setTicketNameOnBreadCrumb(ticket);
      this.updateAdditionalDataForm(ticket);
      this.loadAssigners(ticket);
    });
  }

  updateAdditionalDataForm(ticket: SupportTicketResponse) {
    this.additionalInfoGroup.controls.createdAt.setValue(
      this.datePipe.transform(ticket?.createdAt, 'DD/MM/YYYY HH:mm')
    );
    this.additionalInfoGroup.controls.lastUpdatedAt.setValue(
      this.datePipe.transform(ticket?.updatedAt, 'DD/MM/YYYY HH:mm')
    );
    this.additionalInfoGroup.controls.createdBy.setValue(
      this.getCreatedByName()
    );
  }

  setTicketNameOnBreadCrumb(ticket: SupportTicketResponse) {
    this.route.snapshot.data.breadcrumb = `[WO-${ticket.identifier}] ${ticket.name}`;
  }

  disableFormIfNotEditPage() {
    if (!this.ticket) this.detailsFormGroup.disable();
  }

  initAssignees() {
    this.technician$
      .pipe(
        map((users) => {
          return this.assigneeService.getDropdownAssigneeList({
            formValue: this.ticket?.assignees || [],
            listOfUsers: users,
          });
        }),
        map((users) =>
          users.map((user) => ({
            id: user.id,
            name: `${user.firstName} ${user.lastName}`,
          }))
        )
      )
      .subscribe((users) => {
        this.formDetailsAssignee$.next(users);
      });
  }

  getCountries() {
    this.route.data.subscribe((data) => {
      this.countries$.next(
        data?.countries
          .filter((c: Country) => c.active)
          .map((c: Country) => {
            return { countryISOCode: c.isoCode, name: c.name, id: c.isoCode };
          })
      );
    });
  }

  loadAssigners(ticket: SupportTicketResponse) {
    this.assignees$.next(ticket.assignees);
  }

  initDetailFormGroupValues() {
    const {
      status,
      priority,
      actAt,
      category,
      assignees,
      interventionType,
      scope,
    } = this.ticket;

    this.detailsFormGroup.reset(
      {
        statusId: status ? status.id : '',
        priority: priority || '',
        actAt: actAt || '',
        technicianIds: assignees ? assignees.map((user) => user.id) : [],
        category: category,
        interventionType: interventionType || '',
        scope: scope || '',
      },
      { emitEvent: false }
    );
  }

  openTicketRequest(event: string) {
    this.openRequestEvent.emit(event);
  }

  onOpenActivity(id: string) {
    this.openActivityEvent.emit(id);
  }

  onOpenTicket(ticket: { ticketId: string; ticketType: TicketType }) {
    this.openTicketEvent.emit(ticket);
  }

  addMachine({ machine, userId }) {
    this.ticketSupportMultimachineFormService.addMachine(machine, userId);
  }

  addPart({ part, userId }) {
    this.ticketSupportMultimachineFormService.addPart(part, userId);
  }

  removePart({ partId, userId }) {
    this.ticketSupportMultimachineFormService.deletePart(partId, userId);
  }

  setAccordionTabIndex(index: number) {
    this.accordionOpenedIndex = index;
  }

  handleTabIndexSwitch(tabIndex: number) {
    const dirtyActionForms =
      this.workOrderActionsComponent.getDirtyActionForm();

    const isInfoTabDirty = this.infoTabFormGroup.dirty;

    const openDialog = () => {
      return this.dialog
        .open(DialogComponent, {
          data: {
            title: 'unsavedChangesTitle',
            message: 'unsavedChangesText',
            closeModalText: 'no',
            actionModalText: 'save',
            actionButtonClass: 'button-blue',
            closeButtonClass: 'button-red',
          },
        })
        .afterClosed();
    };

    if (dirtyActionForms?.length > 0) {
      if (tabIndex !== 2) {
        openDialog().subscribe((saveOutput) => {
          if (!saveOutput) {
            //reset form
            return dirtyActionForms.forEach((form) => form.initFormValues());
          } else {
            //save dirty interventions
            dirtyActionForms.forEach((actionForm) => {
              actionForm.onSubmit();
              if (!actionForm.formGroup.valid) {
                this.selected = 2;
                setTimeout(() => {
                  actionForm.scrollToInvalidControl();
                }, 200);
              }
            });
          }
        });
      }
    }

    if (isInfoTabDirty) {
      if (tabIndex !== 0) {
        openDialog().subscribe((save) => {
          if (!save) {
            this.initInfoFormGroupAndFields();
            return false;
          } else {
            this.infoTabFormGroup.markAllAsTouched();
            //save dirty form
            if (this.infoTabFormGroup.valid) {
              this.updateTicket({});
            } else {
              this.selected = 0;
            }
            return true;
          }
        });
      }
    }
  }

  onTabClick(event: MatTabChangeEvent) {
    this.handleTabIndexSwitch(event.index);
  }

  updateSelectedTab(tabIndex: number) {
    this.selected = tabIndex;
  }

  openRequestTab() {
    this.selected = WorkOrderTabsIndex.REQUESTS;
  }

  loadCompanyContacts() {
    this.contactControllerV2
      .getAll({
        queryParams: { companyId: this.ticket$.value.company.id },
      })
      .subscribe((res) => {
        this.companyContacts$.next(res.results);
      });
  }

  loadReports() {
    return this.reportControllerControllerV2
      .getTicketReports({
        reportType: this.reportType,
        ticketId: this.ticket.id,
      })
      .subscribe((res) => this.reports$.next(res.results));
  }

  async updateTicket({ isDetailFormUpdate }: { isDetailFormUpdate?: boolean }) {
    if (this.preview) return;
    if (!this.infoTabFormGroup.valid) {
      return this.snackBar.openErrorFormMissingFields();
    }

    const newData: SupportTicketRequest = {
      actAt: this.detailsFormGroup.get('actAt').value,
      priority: this.detailsFormGroup.get('priority').value as PriorityType,
      statusId: this.detailsFormGroup.get('statusId').value,

      clientInfo: {
        address: this.infoTabFormGroup.controls['clientAddress'].value,
        distance:
          this.infoTabFormGroup.controls['clientDistance'].value?.toString(),
        city: this.infoTabFormGroup.controls['clientCity'].value,
        countryISOCode: this.infoTabFormGroup.controls['countryISOCode'].value,
        zipCode: this.infoTabFormGroup.controls['zipCode'].value,
      },
      closedAt: this.ticket.closedAt,
      companyId: this.ticket.company.id, // As company will never change
      createdAt: this.ticket.createdAt,
      executedAt: this.ticket.executedAt,
      finished: this.ticket.finished,
      id: this.ticket.id,
      malfunctionType: this.infoTabFormGroup.get('malfunctionType').value,
      name: this.infoTabFormGroup.get('name').value,
      internalRef: this.infoTabFormGroup.get('internalRef').value,
      categoryId: this.detailsFormGroup.controls['category'].value?.id,
      interventionType:
        this.detailsFormGroup.controls['interventionType'].value,
      scope: this.detailsFormGroup.controls['scope'].value,
      assigneeIds: this.detailsFormGroup.controls['technicianIds'].value || [],
    };

    const validationResponse =
      await this.ticketSupportValidator.validateBeforeUpdate({
        previousSupportTicketData: this.ticket,
        newSupportTicketData: newData,
        interventions: this.ticket.interventions,
        reports: this.reports$.value,
        statuses: this.statuses$.value,
        workPeriods: this.ticket.workPeriods,
      });

    //If there is some value, means some field are not valid
    if (Object.keys(validationResponse).length > 0) {
      this.initDetailFormGroupValues();
      this.initInfoFormGroupAndFields();
      return;
    }

    const oldContactIds = this.ticket.contacts.map((contact) => contact.id);
    const newContactIds: string[] =
      this.infoTabFormGroup.get('contactId').value;

    const contactsToAdd = newContactIds.filter((newContactId) => {
      return !oldContactIds.includes(newContactId);
    });

    const contactsToRemove = oldContactIds.filter((oldContactId) => {
      return !newContactIds.includes(oldContactId);
    });

    const contactsToRemoveRequests = contactsToRemove.map((contactId) => {
      return this.supportTicketContactController.delete({
        contactId,
        supportTicketId: this.ticket.id,
      });
    });

    const contactsToAddRequests = contactsToAdd.map((contactId) => {
      return this.supportTicketContactController.create({
        contactId,
        supportTicketId: this.ticket.id,
      });
    });

    const updateTicketRequest =
      this.supportTicketController.updateSupportTicket({
        ticketId: this.ticket.id,
        data: newData,
      });

    if (
      contactsToRemoveRequests.length == 0 &&
      contactsToAddRequests.length == 0
    ) {
      updateTicketRequest.subscribe(
        (ticketResponse) => {
          this.ticket$.next(ticketResponse);
          this.initInfoFormGroupAndFields();
          this.isSubmitInfoTabDisabled$.next(true);
        },
        (res) => {
          if (isDetailFormUpdate) {
            this.initDetailFormGroupValues();
          }
        }
      );
    } else {
      forkJoin(...contactsToRemoveRequests, ...contactsToAddRequests).subscribe(
        () => {
          updateTicketRequest.subscribe(
            (ticketResponse) => {
              this.ticket$.next(ticketResponse);
              this.initInfoFormGroupAndFields();
              this.isSubmitInfoTabDisabled$.next(true);
            },
            () => {
              if (isDetailFormUpdate) {
                this.initDetailFormGroupValues();
              }
            }
          );
        }
      );
    }
  }

  navigateToCompanyPage() {
    return this.authService
      .hasPermission([PermissionType.CAN_EDIT_COMPANIES])
      .subscribe((canEditCompanies) => {
        return this.router.navigate([
          `companies/${this.ticket$.value.company.id}${
            canEditCompanies ? '/edit' : ''
          }`,
        ]);
      });
  }

  protected kebabItems: KebabMenuItem[] = [
    { label: 'delete', highlight: 'warn', action: () => this.onDelete() },
  ];

  onDelete() {
    const sub = this.dialog
      .open(DeleteDialogComponent)
      .afterClosed()
      .subscribe((confirmed) => {
        if (confirmed) {
          const sub2 = this.supportTicketController
            .deleteSupportTicketById({ ticketId: this.ticket.id })
            .subscribe(() => {
              this.router.navigate(['/tickets/support']);
            });
          this.sub.add(sub2);
        }
      });
    this.sub.add(sub);
  }

  onInfoTabChangeUpdateSummitAvailability() {
    this.infoTabFormGroup.valueChanges.subscribe((newValue) => {
      const originalValues = {};
      const supportTicket = this.ticket$.value;
      const { clientInfo, contacts } = supportTicket;

      Object.keys(newValue).forEach((key) => {
        if (key == 'clientAddress') {
          return (originalValues[key] = clientInfo.address);
        }

        if (key == 'clientDistance') {
          return (originalValues[key] = clientInfo.distance);
        }

        if (key == 'contactId') {
          return (originalValues[key] = contacts.map((u) => u.id));
        }

        //  Prevent differences btw null and undefined
        if (!newValue[key] && !supportTicket[key]) {
          return (originalValues[key] = newValue[key]);
        }

        originalValues[key] = supportTicket[key];
      });

      const differences = diff(originalValues, newValue);

      if (Object.keys(differences).length == 0) {
        if (!this.isSubmitInfoTabDisabled$.value)
          this.isSubmitInfoTabDisabled$.next(true);
      } else {
        if (this.isSubmitInfoTabDisabled$.value)
          this.isSubmitInfoTabDisabled$.next(false);
      }
    });
  }

  initPriorities() {
    const pro = Object.keys(Priority).map((key) => ({
      id: key,
      name: Priority[key],
      color: PriorityColor[key],
    }));
    this.priorities$.next(pro);
  }

  initFormDetailsFields() {
    this.formDetailsFields = [
      {
        name: 'statusId',
        type: 'select',
        label: 'status',
        size: 6,
        items$: this.statuses$,
        nullable: false,
        translate: true,
        dataTestId: 'status-select-cypress',
      },
      {
        name: 'priority',
        type: 'select',
        label: 'priority',
        size: 6,
        items$: this.priorities$,
        nullable: false,
        translate: true,
        dataTestId: 'priority-test',
      },
      { name: 'actAt', type: 'fulldate', label: 'planedDate', size: 12 },
      {
        name: 'technicianIds',
        type: 'multiple-select',
        label: 'assignee',
        size: 12,
        items$: this.formDetailsAssignee$,
        translate: false,
        dataTestId: 'technicianIds-test',
      },
      {
        name: 'interventionType',
        type: 'select',
        label: 'actionType',
        size: 6,
        items$: this.actionTypesOptions$,
        translate: true,
        matTooltip: 'cantChangeActionType',
      },
      {
        name: 'scope',
        type: 'select',
        label: 'scope',
        size: 6,
        items$: this.supportTicketScopeController.getScopesSelectOptions$(),
        translate: true,
      },
      {
        name: 'category',
        type: 'autocomplete',
        label: 'category',
        size: 12,
        items$: this.workOrderCategoryUtils.getOptions$(),
      },
    ];
  }

  onFormDetailsFieldChangePatchData() {
    this.detailsFormGroup.valueChanges.subscribe((e) => {
      if (this.detailsFormGroup.valid) {
        this.updateTicket({ isDetailFormUpdate: true });
      }
    });
  }

  getCreatedByName() {
    const { firstName, lastName } = this.ticket$.value.createdBy || {};
    if (firstName || lastName) return `${firstName} ${lastName}`;
    return '';
  }

  openBottomSheet(): void {
    const ref = this._bottomSheet.open(BottomSheetMoreOptionsMobile, {
      restoreFocus: true,
      autoFocus: false,
    });
    ref.afterDismissed().subscribe((tabIndex) => {
      if (!tabIndex) {
        return;
      }

      this.updateSelectedTab(tabIndex);
      this.ref.detectChanges();
    });
  }

  protected readonly faList = faList;
  protected readonly faClock = faClock;
  protected readonly faGear = faGear;
  protected readonly faChartLine = faChartLine;
  protected readonly faEllipsis = faEllipsis;

  private _bottomSheet = inject(MatBottomSheet);
  protected readonly WorkOrderTabsIndex = WorkOrderTabsIndex;
}
