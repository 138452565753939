import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MaterialModule } from '@fullyops/shared/material.module';
import { I18NextModule } from 'angular-i18next';

@Component({
  selector: 'fo-kebab-menu',
  standalone: true,
  templateUrl: './kebab-menu.component.html',
  styleUrl: './kebab-menu.component.scss',
  imports: [CommonModule, MaterialModule, I18NextModule],
})
export class KebabMenuComponent {
  @Input() items: KebabMenuItem[] = [];
}

export interface KebabMenuItem {
  label: string;
  highlight?: 'primary' | 'warn';
  action: () => void;
}
