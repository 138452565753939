<fo-toolbar>
  <ng-container leftElements>
    <fo-breadcrumb></fo-breadcrumb>
  </ng-container>
  <ng-container rightElements>
    <fo-action-buttons
      [actionButtons]="actions"
      (actionEvent)="onActionEvent($event)"
    ></fo-action-buttons>
  </ng-container>
</fo-toolbar>
<crm-container [maxSize]="'xl'">
  <div class="board">
    <div class="filters" *ngIf="companies$ | async as companies">
      <crm-pipeline-filters
        [customFilterFields]="customFilterFields"
        [filters$]="filters$"
        [companies]="companies"
        (filtersChangeEvent)="onFiltersChange($event)"
        [statuses$]="statuses$"
        [page]="title"
        [usersOptions$]="usersOptions$"
      ></crm-pipeline-filters>
    </div>
    <mat-tab-group #tabs dynamicHeight class="header-less-tabs teste">
      <mat-tab label="pipeline">
        <crm-pipeline
          [pipeline$]="pipeline$"
          (openEvent)="onOpen($event)"
          (dropTicket)="onDropTicket($event)"
          (loadMoreTickets)="onloadMoreTickets($event)"
        ></crm-pipeline>
      </mat-tab>
      <mat-tab label="list">
        <crm-tickets
          [listTicketsPage$]="listTicketsPage$"
          (openEvent)="onOpen($event)"
          (pageChangeEvent)="handlePageSelection($event)"
        ></crm-tickets>
      </mat-tab>
    </mat-tab-group>
  </div>
</crm-container>
