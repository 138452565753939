<fo-toolbar
  [title]="subProductVariant ? subProductVariant.name : 'newSubProductVariant'"
>
  <ng-template #rightElements>
    <fo-action-buttons
      [actionButtons]="actions"
      (actionEvent)="onActionEvent($event)"
    ></fo-action-buttons>
  </ng-template>
</fo-toolbar>

<ng-container
  *ngIf="
    subProductVariantFormService.subProductVariantForm$
      | async as subProductVariantForm
  "
>
  <form
    [@inOut]="'in'"
    [formGroup]="subProductVariantForm"
    (ngSubmit)="onSubmit()"
  >
    <div class="form-row">
      <div class="col-md-6">
        <crm-upload-image
          [disabled]="preview"
          [uploadService]="uploadService"
          [imageControl]="urlControl"
        ></crm-upload-image>
      </div>
      <div class="col-md-6">
        <crm-form-template
          [formGroup]="subProductVariantForm"
          [formFields]="formFields"
        ></crm-form-template>
      </div>
      <div class="col-md-12">
        <header>
          {{ 'forms:accessories' | i18nextCap }}
        </header>
        <ng-container *ngIf="accessories.length">
          <div cdkDropList class="list" (cdkDropListDropped)="drop($event)">
            <div
              class="list-item"
              formArraryName="accessories"
              *ngFor="let dataLine of accessories.controls; let i = index"
              cdkDrag
            >
              <textarea matInput [formControl]="$any(dataLine)"></textarea>
              <button
                *ngIf="!preview"
                mat-icon-button
                color="warn"
                type="button"
                (click)="removeLine(i)"
              >
                <mat-icon>clear</mat-icon>
              </button>
            </div>
          </div>
          <button
            class="new-line"
            mat-button
            type="button"
            color="primary"
            (click)="createEntry()"
          >
            {{ 'forms:addLine' | i18nextCap }}
          </button>
        </ng-container>
        <crm-empty-warn
          *ngIf="!accessories.length"
          [message]="'subsectionHasNoDetails'"
          [actionLabel]="'addLine'"
          (actionEvent)="createEntry()"
        ></crm-empty-warn>
      </div>
    </div>
  </form>
</ng-container>
