<fo-toolbar [title]="title">
  <ng-container rightElements>
    <fo-action-buttons
      [actionButtons]="actions"
      (actionEvent)="onActionEvent($event)"
    ></fo-action-buttons>
  </ng-container>
</fo-toolbar>

<ng-container *ngIf="machines$ | async as machines">
  <ng-container [ngSwitch]="machines.state">
    <crm-error *ngSwitchCase="state.Error" [@inOut]="'in'"> </crm-error>
    <ng-container *ngSwitchCase="state.Loaded">
      <div [@inOut]="'in'">
        <crm-list-container
          [list$]="machinesList$"
          (openEvent)="onOpen($event)"
        >
        </crm-list-container>
        <ng-container *ngIf="machinesList$ | async as list">
          <crm-empty-warn
            *ngIf="!list.length"
            [message]="'thereAreNoMachinesYet'"
            [actionLabel]="'addNewMachine'"
            (actionEvent)="onAddNew()"
          ></crm-empty-warn>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
