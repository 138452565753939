<fo-toolbar>
  <ng-container leftElements>
    <fo-breadcrumb [lastBreadcrumbLabelOverride]="header"></fo-breadcrumb>
  </ng-container>
  <ng-container rightElements>
    <fo-action-buttons
      [specialActionButtons]="specialActions"
      (actionEvent)="onActionEvent($event)"
    ></fo-action-buttons>
  </ng-container>
</fo-toolbar>

<crm-container [maxSize]="'xl'">
  <div class="row mx-0">
    <!-- Left -->
    <div class="left-col col-md-8">
      <div class="left-page-content">
        <mat-tab-group
          (selectedTabChange)="onChangingTab($event)"
          [selectedIndex]="selectedTab[selectedTab.length - 1]"
          (selectedIndexChange)="selectedTab.push($event)"
        >
          <!-- Working in progress -->
          <mat-tab>
            <ng-template mat-tab-label>{{
              'forms:info' | i18nextCap
            }}</ng-template>
            <div class="remove-negative-margin">
              <crm-form-template
                [formGroup]="requestTicketFormGroup"
                [formFields]="infoTabFormFields"
                class="full-width"
              ></crm-form-template>
            </div>
          </mat-tab>

          <!-- Parts -->
          <mat-tab class="parts-tab">
            <ng-template mat-tab-label class="parts-title">
              {{ 'translation:parts' | i18nextCap }}</ng-template
            >

            <div class="remove-negative-margin button-fix">
              <div class="items-component">
                <fieldset class="items-fieldset">
                  <legend class="items-fieldset--legend">
                    {{ 'translation:parts' | i18nextCap }}
                  </legend>

                  <mat-card
                    class="item"
                    *ngFor="
                      let item of requestTicketFormGroup.controls.parts.value;
                      let i = index
                    "
                  >
                    <div class="items-component-left">
                      <a
                        class="title"
                        [routerLink]="['/parts/' + item.part.id + '/edit']"
                      >
                        <div class="icon blue-icon">
                          <fa-icon
                            [icon]="faScrewdriverWrench"
                            size="lg"
                          ></fa-icon>
                        </div>
                        <div class="text">
                          <span class="part-name">{{ item.part.name }}</span>
                          <span class="ref">Ref: {{ item.part.ref }}</span>
                        </div>
                      </a>
                      <div class="increment-decrement">
                        <button
                          mat-flat-button
                          class="left"
                          type="button"
                          (click)="
                            updatePartQuantity(item.quantity - 1, i, 'parts')
                          "
                        >
                          -
                        </button>
                        <input
                          matInput
                          type="number"
                          [value]="item.quantity"
                          (change)="
                            updatePartQuantity(
                              $any($event.target).valueAsNumber,
                              i,
                              'parts'
                            )
                          "
                        />
                        <button
                          mat-flat-button
                          class="right"
                          type="button"
                          (click)="
                            updatePartQuantity(item.quantity + 1, i, 'parts')
                          "
                        >
                          +
                        </button>
                      </div>

                      <div class="items-component-right">
                        <button
                          mat-icon-button
                          (click)="removePart(i, 'parts', newPartControl)"
                          type="button"
                        >
                          <div class="icon red-icon">
                            <fa-icon [icon]="faTrashCan"></fa-icon>
                          </div>
                        </button>
                      </div>
                    </div>
                  </mat-card>

                  <form (submit)="addPart()" class="add-uncatalogued-row">
                    <mat-form-field
                      class="d-block full-width new-part-input"
                      appearance="outline"
                      [ngClass]="
                        newPartControl.errors && newPartControl.touched
                          ? ''
                          : 'no-padding-bottom'
                      "
                    >
                      <mat-label>{{ 'add' | i18nextCap }}</mat-label>
                      <input
                        matInput
                        [formControl]="newPartControl"
                        #newUncataloguedPartInput
                        #trigger="matAutocompleteTrigger"
                        [matAutocomplete]="addUncataloguedMatComplete"
                      />

                      <mat-autocomplete
                        #addUncataloguedMatComplete="matAutocomplete"
                        [displayWith]="displayUncataloguedPart"
                      >
                        <mat-option
                          *ngFor="let option of filteredGeneralParts$ | async"
                          [value]="option"
                        >
                          <div class="item-option">
                            <span>{{ option.name }}</span>
                            <span class="ref">Ref: {{ option.ref }}</span>
                          </div>
                        </mat-option>
                      </mat-autocomplete>

                      <mat-error>{{
                        newPartControl?.errors?.message | i18nextCap
                      }}</mat-error>
                    </mat-form-field>

                    <div class="new-part-icon">
                      <div
                        (click)="addPart()"
                        class="custom-button-icon custom-button-icon-green"
                      >
                        <mat-icon>add</mat-icon>
                      </div>
                    </div>
                  </form>
                </fieldset>
              </div>
            </div>
          </mat-tab>

          <!-- Supplier -->
          <mat-tab>
            <ng-template mat-tab-label>{{
              'forms:supplier' | i18nextCap
            }}</ng-template>

            <div class="remove-negative-margin">
              <crm-form-template
                [formGroup]="requestTicketFormGroup"
                [formFields]="supplierTabFormFields"
                class="full-width"
              ></crm-form-template>
            </div>
          </mat-tab>
        </mat-tab-group>
        <div class="remove-negative-margin">
          <button
            *ngIf="!id"
            mat-flat-button
            color="primary"
            type="button"
            name="create"
            (click)="createRequest()"
          >
            {{ 'forms:create' | i18nextCap }}
          </button>

          <button
            *ngIf="id"
            mat-flat-button
            color="primary"
            type="button"
            name="save"
            class="button-fix"
            (click)="editRequestTicket()"
          >
            {{ 'forms:save' | i18nextCap }}
          </button>
        </div>
      </div>
    </div>

    <!-- Right -->
    <div class="right-col col-md-4">
      <div class="d-flex flex-column right-column-gap">
        <crm-form-template
          [formGroup]="requestTicketFormGroup"
          [formFields]="trackingFields"
          (selectionChange)="onChange($event)"
        ></crm-form-template>
        <crm-history
          *ngIf="history$"
          [history$]="history$"
          (openActivityEvent)="onOpenActivity($event)"
          (openTicketEvent)="onOpenTicket($event)"
        ></crm-history>
      </div>
    </div>
  </div>
</crm-container>
