<button
  mat-icon-button
  aria-label="More options"
  [matMenuTriggerFor]="kebabMenu"
>
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #kebabMenu>
  @for(item of items; track $index) {
  <button
    mat-menu-item
    (click)="item.action()"
    class="more-options-menu-item"
    [ngClass]="'color-' + item.highlight"
  >
    {{ item.label | i18nextCap }}
  </button>
  }
</mat-menu>
