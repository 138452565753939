<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
  <mat-option *ngFor="let addr of displayedSuggestions$ | async" [value]="addr">
    {{ addr }}
  </mat-option>
</mat-autocomplete>
<mat-chip-grid #chipList>
  <mat-chip
    *ngFor="let addr of values$ | async; index as i"
    (removed)="remove(i)"
  >
    {{ addr }}
    <button matChipRemove>
      <mat-icon>cancel</mat-icon>
    </button>
  </mat-chip>
  <input
    #chipsInput
    matInput
    type="email"
    (blur)="onTouched()"
    (input)="onTextInput($event)"
    [matAutocomplete]="auto"
    [matChipInputFor]="chipList"
    [matChipInputSeparatorKeyCodes]="confirmKeys"
    (matChipInputTokenEnd)="add($event)"
  />
</mat-chip-grid>
