<fo-toolbar [title]="title"></fo-toolbar>
<ng-container *ngIf="usersParts$ | async as parts">
  <ng-container [ngSwitch]="parts.state">
    <crm-error *ngSwitchCase="state.Error" [@inOut]="'in'"> </crm-error>
    <ng-container *ngSwitchCase="state.Loaded">
      <div [@inOut]="'in'">
        <crm-list-container
          [list$]="usersPartsList$"
          (openEvent)="onOpen($event)"
        >
        </crm-list-container>
        <ng-container *ngIf="usersPartsList$ | async as list">
          <crm-empty-warn
            *ngIf="!list.length"
            message="thereAreNoPartsYet"
            actionLabel="addNewPart"
          ></crm-empty-warn>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
