import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  AuthEndpoint,
  Auth,
  User,
  MeEndpoint,
  Permission,
} from '@fullyops/legacy/data';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { tap, catchError, filter, map } from 'rxjs/operators';
import {
  PermissionType,
  PermissionsType,
  RoleGroup,
} from '../ui-shared/utils/crm-types';
import { CookieService } from 'ngx-cookie-service';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { TenantResolverService } from '@fullyops/shared/services/tenant/tenant-resolver.service';
import { Router } from '@angular/router';
import { some, filter as filterLodash } from 'lodash';

type AuthenticatedUser = User | null;

@Injectable({
  providedIn: 'root',
})
export class UiAuthService {
  currentUser$ = new BehaviorSubject<User | null>(null);

  constructor(
    protected auth: AuthEndpoint,
    private me: MeEndpoint,
    private cookieService: CookieService,
    private tenantResolverService: TenantResolverService,
    protected router: Router,
    @Inject(I18NEXT_SERVICE) private i18NextService: ITranslationService
  ) {
    if (this.auth.$authorazied.getValue()) {
      this.fetchUser();
    }
  }

  public get currentUser(): AuthenticatedUser {
    return this.currentUser$.getValue();
  }

  public get authorizedObs() {
    return this.auth.$authorazied;
  }

  hasRole(requestedRole: RoleGroup): boolean {
    return !!this.currentUser.roles.filter((role) => requestedRole == role.name)
      .length;
  }

  hasPermission(
    requestedPermissions: PermissionType[],
    ownerId?: string
  ): Observable<boolean> {
    const userPermissions$ = this.currentUser$.pipe(
      filter((user) => user !== null),
      map((user) => {
        const userPermissions = new Set(
          user.roles.flatMap((role) =>
            role.permissions.map((perm) => perm.name)
          )
        );

        return userPermissions as Set<PermissionType>;
      })
    );

    return userPermissions$.pipe(
      map((permissions) => {
        const validPermissions = filterLodash(requestedPermissions, (e) =>
          permissions.has(e)
        );

        //Will only check the ownerId if we have it. If not, it will consider a permission before having the ticket/entity
        if (validPermissions.length == 1 && ownerId) {
          // Only permission valid is OWN
          if (validPermissions[0].includes('_OWN_')) {
            return this.currentUser$.value?.id == ownerId;
          }
          return true;
        } else {
          if (validPermissions.length > 0) return true;
          else return false;
        }
      })
    );

    return userPermissions$.pipe(
      map((permissions) => {
        const validPermissions = filterLodash(requestedPermissions, (e) =>
          permissions.has(e)
        );

        //Will only check the ownerId if we have it. If not, it will consider a permission before having the ticket/entity
        if (validPermissions.length == 1 && ownerId) {
          // Only permission valid is OWN
          if (validPermissions[0].includes('OWN')) {
            if (this.currentUser$.value.id == ownerId) return true;
            return false;
          }
          return true;
        } else {
          if (validPermissions.length > 0) return true;
          else return false;
        }
      })
    );

    // return this.currentUser$.pipe(
    //   filter((user) => user !== null),
    //   map((user) =>
    //     user.roles.reduce((acc, curr) => acc.concat(curr.permissions), [])
    //   ),
    //   map(
    //     (permissions) =>
    //       !!permissions.filter((permission) =>
    //         requestedPermissions.find((reqPerm) => reqPerm === permission.name)
    //       ).length
    //   )
    // );
  }

  hasPermissionNoAsync(requestedPermissions: PermissionsType[]) {
    const permissionsByRole = this.currentUser$.value.roles.map((role) =>
      role.permissions.map((permission) => permission.name)
    );

    const allPermissions: PermissionsType[] = Array.prototype.concat.apply(
      [],
      permissionsByRole
    );

    return allPermissions.some((permission) =>
      requestedPermissions.includes(permission)
    );
  }

  login(email: string, password: string): Observable<{}> {
    let tenantName = this.tenantResolverService.getActiveTenant();
    return this.auth.login(email, password, tenantName).pipe(
      tap({
        next: (res) => {
          if (res instanceof HttpErrorResponse) return;
          return this.fetchUser();
        },
      })
    );
  }

  fetchUser(pageRefresh: boolean = false) {
    this.me
      .get()
      .pipe(
        filter((response) => response.data !== null),
        map((response) => response.data as User)
      )
      .subscribe((user) => {
        this.currentUser$.next(user);
        this.setApplicationLanguage(pageRefresh);
      });
  }

  refreshToken() {
    this.auth
      .refreshToken()
      .pipe(
        tap((response: Auth) => {
          if (response.jwt !== '') {
            this.me
              .get()
              .pipe(
                filter((r) => r.data !== null),
                map((r) => r.data as User)
              )
              .subscribe(this.currentUser$);

            this.auth.setAuthorize(true);
          } else {
            this.currentUser$.next(null);
          }
        }),
        catchError((error) => {
          this.currentUser$.next(null);
          return of(error);
        })
      )
      .subscribe();
  }

  logout() {
    this.auth
      .logout()
      .pipe(
        tap((_) => {
          this.auth.setAuthorize(false);
          this.currentUser$.next(null);
        })
      )
      .subscribe();
  }

  getPageFilters(page: string) {
    const filterKey = this.currentUser.id + page;
    return JSON.parse(localStorage.getItem(filterKey)) ?? {};
  }

  setPageFilters(page: string, filters: {}) {
    const filterKey = this.currentUser.id + page;
    localStorage.setItem(filterKey, JSON.stringify(filters));
  }

  setApplicationLanguage(pageRefresh: boolean) {
    const cookieLang = this.cookieService.get('lang');
    const appLang = this.i18NextService.language;

    if (
      this.currentUser$.value.locale !== cookieLang ||
      this.currentUser$.value.locale !== appLang
    ) {
      this.i18NextService
        .changeLanguage(this.currentUser$.value.locale)
        .then((_) => {
          const site = window.location.href.split('/')[2];
          this.cookieService.set(
            'lang',
            this.currentUser$.value.locale,
            null,
            site,
            null,
            null,
            null
          );

          const routeChange = this.router.navigate([this.router.url]);
          // angular-18next does not recognize a language change if the page isn't reloaded
          // TODO: Implement OnPush change detection strategy and use the i18nextEager pipe.
          // TODO: See https://www.npmjs.com/package/angular-i18next
          if (pageRefresh) {
            routeChange.then(() => window.location.reload());
          }
        });
    }
  }
}
