<div
  class="work-order-actions"
  *ngIf="(showUncataloguedPart$ | async) !== null"
>
  <ng-container *hasPermission="{ permissions: permissionToCreateAction }">
    <ng-container *ngIf="!isDetailPage">
      <ng-container *ngIf="ticket$ | async as ticket">
        <ng-container *ngIf="ticket">
          <div class="add-action-container">
            <button
              mat-stroked-button
              type="button"
              color="primary"
              (click)="openActionForm(ticket, null)"
            >
              <mat-icon>add_circle</mat-icon>
              {{ 'add' | i18nextCap }}
            </button>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="ticket$ | async as ticket">
    <ng-container *ngIf="ticket.interventions.length > 0">
      <mat-card
        *ngFor="
          let intervention of (ticket$ | async).interventions;
          let i = index
        "
        class="action-item"
        [attr.data-testid]="'action-' + i"
      >
        <div class="action-item-left">
          <mat-icon>build</mat-icon>
          <div class="details">
            <span class="title">{{ intervention.title }}</span>
            <div class="subtitle">
              <span class="date-time">{{
                intervention.createdAt
                  | momentTimezoneToAngularTimezone: 'DD/MM/YYYY'
              }}</span>
              <span class="date-time">
                -
                {{
                  intervention.createdAt
                    | momentTimezoneToAngularTimezone: 'HH:mm'
                }}</span
              >
            </div>
          </div>
        </div>
        <div class="action-item-right">
          <div [matTooltip]="'EDIT_WORK_ORDER_ACTION_TOOLTIP' | i18nextCap">
            <button
              mat-icon-button
              type="button"
              class="color-grey-600"
              (click)="openActionForm(ticket, intervention)"
            >
              <mat-icon>open_in_new</mat-icon>
            </button>
          </div>
        </div>
      </mat-card>
    </ng-container>
  </ng-container>
</div>
