<div class="mode-bar">
  <div class="left">
    <button mat-stroked-button class="today-button" (click)="gotoToday()">
      <mat-icon>today</mat-icon> {{ 'today' | i18nextCap }}
    </button>

    <button mat-stroked-button [matMenuTriggerFor]="modeMenu">
      <fa-icon [icon]="faBottom"></fa-icon> {{view | i18nextCap}}
    </button>
  </div>

  <div class="calendar-title">
    <!-- <button mat-icon-button (click)="fastBack()">
      <mat-icon>fast_rewind</mat-icon>
    </button> -->
    <button mat-icon-button (click)="back()">
      <fa-icon [icon]="faleft"></fa-icon>
    </button>
    <div class="title">{{headerText | i18nextCap}}</div>

    <button mat-icon-button (click)="forward()">
      <fa-icon [icon]="faright"></fa-icon>
    </button>
    <!-- <button mat-icon-button (click)="fastForward()">
      <mat-icon>fast_forward</mat-icon>
    </button> -->
  </div>
</div>
<div class="fill-space calendar-container">
  <full-calendar [options]="options" class="full-calendar">
    <ng-template #eventContent let-args>
      <div
        class="event-item"
        [ngClass]="{'event-call': args.event.kind === 'activity'}"
      >
        <div class="event-item--content">
          <div
            *ngIf="args.event?.extendedProps?.orig?.color"
            class="circle-icon"
            [ngStyle]="{backgroundColor: args.event.extendedProps.orig.color, 
          borderColor: args.event.extendedProps.orig.color}"
          ></div>
          <div class="event-item--text">
            {{args.event.title}} {{args.event?.extendedProps?.icon}}
          </div>
        </div>
        <ng-template [ngIf]="args.event?.extendedProps?.assignees">
          <div class="buubles">
            <fo-people-bubbles
              [bubbles]="args.event.extendedProps?.assignees"
              [maxLength]="3"
            ></fo-people-bubbles>
          </div>
        </ng-template></div
    ></ng-template>
  </full-calendar>
</div>
<div class="popup" #popup>
  <ng-container
    *ngIf="(hoveredEvent$ | async) != null"
    [ngTemplateOutlet]="popupTemplate"
    [ngTemplateOutletContext]="{$implicit: (hoveredEvent$ | async)}"
  >
  </ng-container>
</div>
<mat-menu #modeMenu class="calendar-view-menu">
  <div></div>
  <button mat-menu-item (click)="switchView('dayGridMonth')">
    {{'month' | i18nextCap}}
  </button>
  <button mat-menu-item (click)="switchView('timeGridWeek')">
    {{'week' | i18nextCap}}
  </button>
  <button mat-menu-item (click)="switchView('timeGridDay')">
    {{'day' | i18nextCap}}
  </button>
  <hr />
  <mat-slide-toggle [(ngModel)]="options.weekends" color="primary">
    {{'showWeekends' | i18nextCap}}
  </mat-slide-toggle>
</mat-menu>
