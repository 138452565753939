import { CommonModule } from '@angular/common';
import { Component, Input, TemplateRef } from '@angular/core';
import { MaterialModule } from '@fullyops/shared/material.module';
import { I18NextCapPipe, I18NextModule } from 'angular-i18next';
@Component({
  selector: 'fo-toolbar',
  standalone: true,
  imports: [CommonModule, MaterialModule, I18NextModule],
  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.scss',
})
export class ToolbarComponent {
  @Input() title: string;
}
