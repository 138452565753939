import { DialogService } from '@fullyops/legacy/ui/ui-shared/dialog/dialog.service';
import {
  ActionButton,
  ActionButtons,
} from './../../../legacy/ui/ui-shared/utils/crm-types';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HideIfNotAllowedDirective } from '@fullyops/shared/directives/hide-if-not-allowed.directive';
import { MaterialModule } from '@fullyops/shared/material.module';
import { I18NextModule } from 'angular-i18next';
import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { SharedModule } from '@fullyops/shared/shared.module';
import { CdkMenu } from '@angular/cdk/menu';

@Component({
  selector: 'fo-action-buttons',
  standalone: true,
  imports: [
    MaterialModule,
    I18NextModule,
    CdkOverlayOrigin,
    SharedModule,
    CdkConnectedOverlay,
    CdkMenu,
  ],
  templateUrl: './action-buttons.component.html',
  styleUrl: './action-buttons.component.scss',
  providers: [HideIfNotAllowedDirective],
})
export class ActionButtonsComponent {
  @Input() buttons: ActionButton;
  @Input() specialActionButtons?: ActionButton[];
  @Input() actionButtons: ActionButtons;
  @Output() actionEvent = new EventEmitter();

  isMoreOptionsOverlayOpened = false;

  constructor(private dialogService: DialogService) {}

  fireAction(action) {
    if (action === 'delete') {
      const dialogRef = this.dialogService.openDialogBeforeDelete();

      dialogRef.afterClosed().subscribe((saveOutput) => {
        if (saveOutput) {
          this.actionEvent.emit(action);
        }
      });
    } else if (action === 'back') {
      history.back();
    } else {
      this.actionEvent.emit(action);
    }
  }
}
