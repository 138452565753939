import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { CapitalizePipe } from './pipes/capitalize.pipe';
import { TrimPipe } from './pipes/trim.pipe';
import { SortByDirective } from './directives/sortby.directive';
import { MaterialModule } from './material.module';
import { ImagePipe } from './pipes/image.pipe';
import { MomentTimezoneToAngularTimezonePipe } from './pipes/moment-timezone-to-angular-timezone.pipe';
import { DisableLinkIfNotAllowedDirective } from './directives/disable-link-if-not-allowed.directive';
import { HasPermissionDirective } from './directives/hasPermission.directive';
import { HideIfNotAllowedDirective } from './directives/hide-if-not-allowed.directive';
import { HideIfTenantConfigurationDisabledDirective } from './directives/hide-if-tenant-configuration-disabled.directive';
import { FedMaskDirective } from './directives/mask.directive';

@NgModule({
  imports: [CommonModule, MaterialModule],
  exports: [
    CommonModule,
    FormsModule,
    CapitalizePipe,
    ImagePipe,
    TrimPipe,
    MomentTimezoneToAngularTimezonePipe,
    SortByDirective,
    MaterialModule,
    DisableLinkIfNotAllowedDirective,
    HasPermissionDirective,
    HideIfNotAllowedDirective,
    HideIfTenantConfigurationDisabledDirective,
    FedMaskDirective,
  ],
  declarations: [
    CapitalizePipe,
    ImagePipe,
    TrimPipe,
    SortByDirective,
    MomentTimezoneToAngularTimezonePipe,
    DisableLinkIfNotAllowedDirective,
    HasPermissionDirective,
    HideIfNotAllowedDirective,
    HideIfTenantConfigurationDisabledDirective,
    FedMaskDirective,
  ],
})
export class SharedModule {}
