import {
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PaginationResult } from '../utils/crm-types';
import {
  ListTable$,
  PaginatedListTable$,
} from '@fullyops/shared/util/crm-types';
import {
  MatColumnDef,
  MatTable,
  MatTableDataSource,
} from '@angular/material/table';

@Component({
  selector: 'crm-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
  @Input() paginatedList$: PaginatedListTable$ = new BehaviorSubject<
    PaginationResult<any>
  >({
    currentPage: 0,
    pageSize: 0,
    results: [],
    hasPrevious: false,
    hasNext: false,
    totalPages: 0,
    count: 0,
  });
  @Input() list$: Observable<any>;
  @Input() displayedColumns: Array<string>;
  @Output() onPageChange = new EventEmitter<number>();
  @Output() onClick = new EventEmitter<string>();

  // this is where the magic happens:
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ContentChildren(MatColumnDef) columnDefs: QueryList<MatColumnDef>;

  emptyData = new MatTableDataSource([]);

  // after the <ng-content> has been initialized, the column definitions are available.
  // All that's left is to add them to the table ourselves:
  ngAfterContentInit() {
    this.columnDefs.forEach((columnDef) => this.table.addColumnDef(columnDef));
  }

  ngOnInit() {
    this.paginatedList$.subscribe((e) => {
      const matTable = document.getElementById('matTable');
      if (matTable) matTable.scrollTop = 0;
    });
  }

  handleClick({ id }) {
    this.onClick.emit(id);
  }

  handlePageEvent(pageIndex: number) {
    this.onPageChange.emit(pageIndex);
  }

  handleOnScroll() {
    //this.onPageChange.emit(this.pageIndex + 1)
  }

  handleRowClick(row: any) {
    if (this.paginatedList$.value.count > 0) {
      this.handleClick(row);
    }
  }
}
