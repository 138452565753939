<h2 mat-dialog-title>{{ 'areYouSureYouWantToDelete?' | i18nextCap }}</h2>
<mat-dialog-content>
  <span>{{ 'onceDeletedThisActionCannotBeUndone' | i18nextCap }}</span>
</mat-dialog-content>
<div mat-dialog-actions align="end">
  <button mat-button color="primary" [mat-dialog-close]="false">
    {{ 'cancel' | i18nextCap }}
  </button>
  <button mat-flat-button color="warn" [mat-dialog-close]="true">
    {{ 'delete' | i18nextCap }}
  </button>
</div>
