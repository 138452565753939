import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { SnackbarService } from '@fullyops/shared/services/snackbar.service';
import { MaterialModule } from '@fullyops/shared/material.module';
import { BehaviorSubject, combineLatest, map, Subscription } from 'rxjs';
import { normalizeForSearch } from '@fullyops/shared/normalize-for-search';

@Component({
  selector: 'fo-emails-input',
  standalone: true,
  templateUrl: './emails-input.component.html',
  imports: [CommonModule, MaterialModule],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: EmailsInputComponent,
    },
  ],
})
export class EmailsInputComponent
  implements
    OnInit,
    OnDestroy,
    ControlValueAccessor,
    MatFormFieldControl<string[]>
{
  @Input() suggestions: string[] = [];

  protected confirmKeys = [ENTER, SPACE, COMMA];

  protected values$ = new BehaviorSubject<string[]>([]);
  protected searchText$ = new BehaviorSubject<string>('');
  protected displayedSuggestions$ = combineLatest([
    this.values$,
    this.searchText$,
  ]).pipe(
    map(([values, searchText]) => {
      const pattern = normalizeForSearch(searchText);
      return this.suggestions.filter(
        (s) =>
          values.indexOf(s) === -1 &&
          normalizeForSearch(s).indexOf(pattern) !== -1
      );
    })
  );

  private subscriptions = new Subscription();

  ngOnInit() {
    const sub = this.values$.subscribe((addrs) => {
      this.onChanged(addrs);
      this.onTouched();
    });
    this.subscriptions.add(sub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  protected add(event: MatChipInputEvent) {
    const addr = event.value.trim();
    if (!emailPattern.test(addr)) {
      this.snackbar.openError({ message: 'pleaseSelectValidEmail' });
      return;
    }
    const i = this.values$.value.indexOf(addr);
    if (i === -1) {
      this.values$.next([...this.values$.value, addr]);
      this.internalInput.value = '';
    }
  }

  protected remove(i: number) {
    const newValues = [...this.values$.value];
    newValues.splice(i, 1);
    this.values$.next(newValues);
  }

  protected onTextInput(ev: Event) {
    const el = ev.currentTarget as HTMLInputElement;
    this.searchText$.next(el.value);
  }

  // implementation of ControlValueAccessor to enable use with reactive forms

  private onChanged = (xs: string[]) => {};
  protected onTouched = () => {};

  writeValue(v: string[]) {
    this.values$.next(v);
  }

  registerOnChange(f: (v: string[]) => void) {
    this.subscriptions.add(this.values$.subscribe(f));
  }

  registerOnTouched(f: () => void) {
    this.onTouched = f;
  }

  setDisabledState(isDisabled: boolean) {
    this.internalInput.disabled = isDisabled;
  }

  // implementation of MatFormFieldControl to enable use inside mat-form-field

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private snackbar: SnackbarService
  ) {
    if (ngControl != null) {
      ngControl.valueAccessor = this;
    }
  }

  @ViewChild(MatInput, { static: true })
  protected internalInput: MatInput;

  get value() {
    return this.values$.value;
  }

  set value(xs: string[]) {
    this.values$.next(xs);
  }

  get stateChanges() {
    return this.internalInput.stateChanges;
  }

  get id() {
    return this.internalInput.id;
  }

  get placeholder() {
    return this.internalInput.placeholder;
  }

  get focused() {
    return this.internalInput.focused;
  }

  get empty() {
    return this.internalInput.empty && this.values$.value.length === 0;
  }

  get shouldLabelFloat() {
    return this.values$.value.length > 0 || this.internalInput.value !== '';
  }

  get required() {
    return this.ngControl.control.hasValidator(Validators.required);
  }

  get disabled() {
    return this.ngControl.disabled;
  }

  get errorState() {
    return this.ngControl.touched && this.ngControl.errors !== null;
  }

  get controlType() {
    return this.internalInput.controlType;
  }

  get autofilled() {
    return this.internalInput.autofilled;
  }

  get userAriaDescribedBy() {
    return this.internalInput.userAriaDescribedBy;
  }

  setDescribedByIds(ids: string[]) {
    this.internalInput.setDescribedByIds(ids);
  }

  onContainerClick(_: MouseEvent) {
    this.internalInput.onContainerClick();
  }
}

// Regular expression pattern for a basic email validation
const emailPattern =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
