<fo-toolbar>
  <ng-container leftElements>
    <fo-breadcrumb></fo-breadcrumb>
  </ng-container>
  <ng-container rightElements>
    <fo-kebab-menu [items]="kebabItems"> </fo-kebab-menu>
  </ng-container>
</fo-toolbar>
<crm-container [maxSize]="'xl'" [noPaddingTop]="true">
  <ng-container
    *ngIf="
      ticketSupportMultimachineFormService.form$
        | async as ticketSupportMultimachineForm
    "
  >
    <form
      #ngForm="ngForm"
      [formGroup]="form"
      (ngSubmit)="onSubmit()"
      class="main-form remove-form-margin-and-padding"
    >
      <div class="row mx-0 overflow-auto">
        <!-- Left -->
        <div class="left-col col-md-8" #mainTabGroup>
          <mat-tab-group
            (selectedTabChange)="onTabClick($event)"
            [(selectedIndex)]="selected"
            class="remove-mat-tab-group-margins main-navigation-tabs"
            animationDuration="0ms"
          >
            <!-- Info table  -->
            <mat-tab [attr.data-testid]="'ticket-support-info-tab'">
              <ng-template mat-tab-label
                >{{ 'forms:info' | i18nextCap }}
              </ng-template>
              <div class="tab-content">
                <form class="info-tab-form" (ngSubmit)="updateTicket({})">
                  <crm-form-template
                    class="hidden-md hidden-lg"
                    [formGroup]="detailsFormGroup"
                    [formFields]="formDetailsFields"
                  ></crm-form-template>
                  <crm-form-template
                    [formGroup]="infoTabFormGroup"
                    [formFields]="infoTabFormFields"
                  ></crm-form-template>
                  <button
                    type="submit"
                    mat-flat-button
                    color="primary"
                    class="button-fix"
                    [disabled]="isSubmitInfoTabDisabled$ | async"
                  >
                    {{ 'forms:save' | i18nextCap }}
                  </button>
                </form>
              </div>
            </mat-tab>

            <!-- Actions table  -->
            <ng-container *hasPermission="{ permissions: requestPermissions }">
              <mat-tab
                *ngIf="entity"
                [attr.data-testid]="'ticket-support-intervention-tab'"
              >
                <ng-template mat-tab-label
                  >{{ 'translation:actions' | i18nextCap }}
                </ng-template>
                <div class="tab-content" *ngIf="ticket$ | async">
                  <crm-work-order-actions
                    [isDetailPage]="preview"
                    [ticket$]="ticket$"
                    [ticketId]="ticketId"
                    (openRequestTab)="openRequestTab()"
                    [openedIndex]="accordionOpenedIndex"
                  ></crm-work-order-actions>
                </div>
              </mat-tab>
            </ng-container>

            <!-- Requests table  -->
            <ng-container *hasPermission="{ permissions: requestPermissions }">
              <mat-tab
                *ngIf="entity"
                [attr.data-testid]="'ticket-support-request-tab'"
              >
                <ng-template mat-tab-label>
                  {{ 'forms:partRequests' | i18nextCap }}
                </ng-template>
                <div class="tab-content">
                  <crm-ticket-support-request-parts
                    [isDetailPage]="preview"
                    [ticket]="$any(ticket)"
                    [formGroup]="
                      $any(ticketSupportMultimachineForm.controls.ticketParts)
                    "
                    [users$]="technician$"
                    [usersParts$]="usersParts$"
                    [requests]="entity.requests"
                    (openEvent)="openTicketRequest($any($event))"
                    (removePartEvent)="removePart($event)"
                    (addPartEvent)="addPart($event)"
                  ></crm-ticket-support-request-parts>
                </div>
              </mat-tab>
            </ng-container>

            <!-- Reports table  -->
            <ng-container *hasPermission="{ permissions: reportPermissions }">
              <mat-tab
                *ngIf="entity"
                [attr.data-testid]="'ticket-support-reports-tab'"
              >
                <ng-template mat-tab-label
                  >{{ 'forms:reports' | i18nextCap }}
                </ng-template>
                <div class="tab-content">
                  <crm-report-list
                    [ticketId]="ticket.id"
                    [isDetailPage]="preview"
                    [withProposal]="true"
                    [reportType]="reportType"
                    [reports$]="reports$"
                    [ticket$]="ticket$"
                  ></crm-report-list>
                </div>
              </mat-tab>
            </ng-container>

            <!-- Working periods and travels (mobile only) -->
            <ng-container>
              <mat-tab aria-labelledby="hidden-md-lg">
                <ng-template mat-tab-label
                  >{{ 'forms:workLogs' | i18nextCap }}
                </ng-template>
                <div class="tab-content">
                  <crm-working-hours-registry
                    [isDetailPage]="preview"
                    [usersAssignees$]="assignees$"
                    [ticket$]="ticket$"
                    *ngIf="ticket"
                  ></crm-working-hours-registry>
                </div>
              </mat-tab>
            </ng-container>

            <ng-container>
              <mat-tab aria-labelledby="hidden-md-lg">
                <ng-template mat-tab-label
                  >{{ 'forms:history' | i18nextCap }}
                </ng-template>
                <div class="tab-content">
                  <crm-history
                    *ngIf="history$ && ticket"
                    [history$]="history$"
                    (openActivityEvent)="onOpenActivity($event)"
                    (openTicketEvent)="onOpenTicket($any($event))"
                  ></crm-history>
                </div>
              </mat-tab>
            </ng-container>
          </mat-tab-group>
        </div>

        <!-- Right -->
        <div class="right-col col-md-4 hidden-sm hidden-xs">
          <div class="d-flex flex-column right-column-gap overflow-auto">
            <mat-accordion>
              <crm-expansion-panel title="{{ 'forms:details' | i18nextCap }}">
                <crm-form-template
                  [formGroup]="detailsFormGroup"
                  [formFields]="formDetailsFields"
                ></crm-form-template>
                <crm-form-template
                  [formGroup]="additionalInfoGroup"
                  [formFields]="additionalFormFields"
                ></crm-form-template>
              </crm-expansion-panel>

              <crm-expansion-panel
                title="{{ 'forms:workingRegistry' | i18nextCap }}"
              >
                <crm-working-hours-registry
                  [isDetailPage]="preview"
                  [usersAssignees$]="assignees$"
                  [ticket$]="ticket$"
                  *ngIf="ticket"
                ></crm-working-hours-registry>
              </crm-expansion-panel>

              <crm-history
                *ngIf="history$ && ticket"
                [history$]="history$"
                (openActivityEvent)="onOpenActivity($event)"
                (openTicketEvent)="onOpenTicket($any($event))"
              ></crm-history>
            </mat-accordion>
          </div>
        </div>
      </div>
    </form>
  </ng-container>
</crm-container>
<footer class="hidden-lg hidden-md">
  <mat-toolbar class="toolbarNav">
    <button
      mat-flat-button
      (click)="updateSelectedTab(0)"
      [ngClass]="{ active: selected == 0 }"
    >
      <fa-icon [icon]="faList" size="lg" class="icon-color"></fa-icon>
      <span class="label">{{ 'forms:details' | i18nextCap }}</span>
    </button>
    <button
      mat-flat-button
      (click)="updateSelectedTab(4)"
      [ngClass]="{ active: selected == 4 }"
    >
      <fa-icon [icon]="faClock" size="lg" class="icon-color"></fa-icon>
      <span class="label">{{ 'translation:workLog' | i18nextCap }}</span>
    </button>
    <button
      mat-flat-button
      (click)="updateSelectedTab(1)"
      [ngClass]="{ active: selected == 1 }"
    >
      <fa-icon [icon]="faGear" size="lg" class="icon-color"></fa-icon>
      <span class="label">{{ 'translation:actions' | i18nextCap }}</span>
    </button>
    <button
      mat-flat-button
      (click)="updateSelectedTab(3)"
      [ngClass]="{ active: selected == 3 }"
    >
      <fa-icon [icon]="faChartLine" size="lg" class="icon-color"></fa-icon>
      <span class="label">{{ 'forms:reports' | i18nextCap }}</span>
    </button>
    <button
      mat-flat-button
      (click)="openBottomSheet()"
      [ngClass]="{
        active:
          selected == WorkOrderTabsIndex.HISTORY ||
          selected == WorkOrderTabsIndex.REQUESTS
      }"
    >
      <fa-icon [icon]="faEllipsis" size="lg" class="icon-color"></fa-icon>
      <span class="label">{{ 'translation:more' | i18nextCap }}</span>
    </button>
  </mat-toolbar>
</footer>
