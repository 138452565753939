<fo-toolbar>
  <ng-container leftElements>
    <fo-breadcrumb></fo-breadcrumb>
  </ng-container>
  <ng-container rightElements>
    <fo-action-buttons
      [actionButtons]="actions"
      (actionEvent)="onActionEvent($event)"
    ></fo-action-buttons>
  </ng-container>
</fo-toolbar>
<crm-container [maxSize]="'xl'">
  <div [@inOut]="'in'" class="content-wrapper" *ngIf="listContactsPagination$">
    <div class="table-container">
      <div class="table-container-header">
        <crm-contacts-filters
          [filters$]="filters$"
          (filtersChangeEvent)="onFiltersChange($event)"
        ></crm-contacts-filters>
      </div>
      <crm-table
        [paginatedList$]="listContactsPagination$"
        [displayedColumns]="displayedColumns"
        (onPageChange)="onPageChange($event)"
        (onClick)="onOpen($event)"
      >
        <!-- Name Column-->
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef>
            {{ 'name' | i18nextCap }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="cell-row">
              <div>
                <span class="title">{{ element.name }}</span
                ><span *ngIf="element.jobPosition" class="subtitle">{{
                  element.jobPosition
                }}</span>
              </div>
              <div>
                <span *ngIf="element.email" class="subtitle desktop-only"
                  ><mat-icon>mail</mat-icon> {{ element.email }}</span
                >
                <span *ngIf="element.mobileNumber" class="subtitle"
                  ><mat-icon>phone</mat-icon> {{ element.mobileNumber }}</span
                >
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <!-- Company Column-->
        <ng-container matColumnDef="company">
          <mat-header-cell *matHeaderCellDef>
            {{ 'company' | i18nextCap }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span class="title"> {{ element.company.name }}</span>
          </mat-cell>
        </ng-container>
      </crm-table>
    </div>
  </div>
</crm-container>
