<h2 mat-dialog-title>{{ data.title | i18nextCap }}</h2>
<mat-dialog-content>
  <form (submit)="onSubmit()" [formGroup]="form">
    <mat-form-field>
      <mat-label>{{ 'to' | i18nextCap }}</mat-label>
      <fo-emails-input
        [suggestions]="data.suggestions ?? []"
        formControlName="to"
      ></fo-emails-input>
    </mat-form-field>
    <mat-form-field *ngIf="data.bcc">
      <mat-label>BCC</mat-label>
      <fo-emails-input
        [suggestions]="data.suggestions ?? []"
        formControlName="bcc"
      ></fo-emails-input>
    </mat-form-field>
    <input type="submit" hidden />
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-flat-button
    color="basic"
    class="button-warning"
    [mat-dialog-close]="null"
  >
    {{ 'cancel' | i18nextCap }}
  </button>
  <button mat-flat-button color="primary" (click)="onSubmit()" cdkFocusInitial>
    {{ data.okLabel | i18nextCap }}
  </button>
</mat-dialog-actions>
