<fo-toolbar [title]="entity ? entity.serialNumber : 'newEquipment'">
  <ng-container rightElements>
    <fo-action-buttons
      [actionButtons]="actions"
      (actionEvent)="onActionEvent($event)"
    ></fo-action-buttons>
  </ng-container>
</fo-toolbar>
<ng-container
  *ngIf="clientEquipmentFormService.form$ | async as clientEquipmentForm"
>
  <form
    #ngForm="ngForm"
    [@inOut]="'in'"
    [formGroup]="clientEquipmentForm"
    (ngSubmit)="onSubmit()"
  >
    <div class="form-row">
      <div class="col-md-12">
        <crm-form-template
          [formGroup]="clientEquipmentForm"
          [formFields]="formFields"
        ></crm-form-template>
      </div>
    </div>
  </form>
</ng-container>
