import * as moment from 'moment-timezone';

export const convertMiliSecondsToTimeString = (milliseconds: number) => {
  let diff = moment.duration(milliseconds);
  let minutes = diff.minutes();
  let hours = diff.hours();
  let days = diff.days();

  const hoursString = hours > 0 ? `${hours}h` : '';
  const minutesString = minutes > 0 ? `${minutes} min` : '';
  const daysString = days > 0 ? `${days}d` : '';

  return `${daysString} ${hoursString} ${minutesString}`;
};

export const getFormattedDate = (props: {
  minutesShift?: number;
  date: moment.Moment;
}) => {
  const { date, minutesShift } = props;
  const momentDate = date.clone();
  const roundedMinutes = Math.ceil(momentDate.minutes() / 15) * 15;

  momentDate.minutes(roundedMinutes + (minutesShift ? minutesShift : 0));
  return momentDate.toDate();
};
