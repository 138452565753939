<ng-content select="[pipeline-toolbar]"></ng-content>
<mat-drawer-container>
  <mat-drawer-content>
    <div class="contentArea">
      <div class="pipelineControls">
        <ng-content select="[pipeline-sort-options]"></ng-content>
        <span style="flex: 1 1 auto"></span>
        <button
          mat-icon-button
          [matBadge]="filterCount$ | async"
          [matBadgeHidden]="(filterCount$ | async) === 0"
          matBadgePosition="before"
          matBadgeSize="small"
          (click)="filterBar.toggle()"
        >
          <fa-icon [icon]="faFilter" size="lg"></fa-icon>
          {{ 'translation:filters' | i18nextCap }}
        </button>
      </div>
      <div class="pipelineContent">
        <div
          *ngFor="let tab of tabs; index as i"
          class="pipelineTab"
          [class.pipelineTabHiddenLeft]="i < selectedTab"
          [class.pipelineTabHiddenRight]="i > selectedTab"
        >
          <ng-container [ngTemplateOutlet]="tab.templateRef"></ng-container>
        </div>
      </div>
    </div>
  </mat-drawer-content>
  <mat-drawer #filterBar position="end" mode="side">
    <div class="filter-header">
      <div class="title-container">
        <span>{{ 'translation:filters' | i18nextCap }}</span>
        <ng-content select="[pipeline-filter-saved-filters]"></ng-content>
      </div>
      <button mat-icon-button (click)="filterBar.toggle()">
        <fa-icon [icon]="faXmark" size="xl"></fa-icon>
      </button>
    </div>
    <div class="filter-content">
      <ng-content select="[pipeline-filter-options]"></ng-content>
    </div>
    <div class="filter-footer">
      <ng-content select="[pipeline-filter-reset-values]"></ng-content>
      <button
        class="hidden-md hidden-lg"
        mat-flat-button
        color="primary"
        (click)="filterBar.toggle()"
      >
        {{'showResults' | i18nextCap: { count: ticketCount } }}
      </button>
    </div>
  </mat-drawer>
</mat-drawer-container>
