import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Product, UnsavedProduct, SubProduct } from '@fullyops/legacy/data';
import { ProductFormService } from './product-form.service';
import { PermissionType } from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import { FormComponent } from '@fullyops/legacy/ui/ui-shared/utils/form.component';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import { BehaviorSubject } from 'rxjs';
import { HotToastService } from '@ngneat/hot-toast';
import { I18NextPipe } from 'angular-i18next';
import { SnackbarService } from '@fullyops/shared/services/snackbar.service';
import { UiAuthService } from '../ui-auth.service';

@Component({
  selector: 'crm-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.scss'],
  providers: [ProductFormService],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class ProductFormComponent
  extends FormComponent<Product, UnsavedProduct, ProductFormService>
  implements OnInit
{
  @Input() uploadService: (
    file: File,
    extension: string
  ) => BehaviorSubject<{ url: string }>;
  @Output() newSupProductEvent = new EventEmitter<{
    subProductId: null;
    unsavedProduct: UnsavedProduct;
  }>();
  @Output() editSupProductEvent = new EventEmitter<{
    subProductId: string;
    unsavedProduct: UnsavedProduct;
  }>();
  @Output() deleteSupProductEvent = new EventEmitter<{
    subProductId: string;
    unsavedProduct: UnsavedProduct;
  }>();
  @Output() editVariantEvent = new EventEmitter<{
    subProductId: string;
    unsavedProduct: UnsavedProduct;
    variantId: string;
  }>();

  @Output() cloneProductEvent = new EventEmitter<Product>();

  subproducts$ = new BehaviorSubject<SubProduct[]>([]);

  constructor(
    public productsFormService: ProductFormService,
    protected snackBarOld: MatSnackBar,
    public snackBar: SnackbarService,
    public authService: UiAuthService
  ) {
    super(snackBar, authService);

    this.formService = productsFormService;
    this.formEditPermissions = [PermissionType.CAN_EDIT_PRODUCTS];
    this.draft$ = new BehaviorSubject<UnsavedProduct>(
      new UnsavedProduct('', 0, 0, '', '', [], [], {}, [], null, null)
    );
  }

  ngOnInit() {
    this.initForm();
    this.initFields();
    this.subproducts$.next(
      this.entity ? (this.entity as Product).subproducts : []
    );
  }

  initFields() {
    this.formFields = [
      { name: 'name', label: 'name', type: 'text', size: 12 },
      { name: 'ref', label: 'ref', type: 'text', size: 12 },
      { name: 'stock', label: 'stock', type: 'number', size: 6 },
      { name: 'price', label: 'price', type: 'price', size: 6 },
      {
        name: 'catalogueUrl',
        label: 'catalogueUrl',
        type: 'upload',
        size: 12,
      },
      {
        name: 'description',
        label: 'description',
        type: 'textarea',
        size: 12,
      },
    ];
  }

  openSnackBar(message: string, action: string) {
    this.snackBarOld.open(message, action, {
      duration: 3000,
    });
  }

  newSubProduct() {
    if (this.ngForm.valid) {
      this.newSupProductEvent.emit({
        subProductId: null,
        unsavedProduct: this.productsFormService.getDraft(),
      });
    } else {
      this.productsFormService.markAllAsTouched();
      this.openSnackBar(
        'Sub-products only available after product creation. Create product first.',
        'Close'
      );
    }
  }

  editSubProduct(subProductId: string) {
    if (this.ngForm.valid) {
      this.editSupProductEvent.emit({
        subProductId,
        unsavedProduct: this.productsFormService.getDraft(),
      });
    } else {
      this.productsFormService.markAllAsTouched();
    }
  }

  deleteSubProduct(subProductId: string) {
    if (this.ngForm.valid) {
      this.deleteSupProductEvent.emit({
        subProductId,
        unsavedProduct: this.productsFormService.getDraft(),
      });
    } else {
      this.productsFormService.markAllAsTouched();
    }
  }

  editVariant({ subProductId, variantId }) {
    if (this.ngForm.valid) {
      this.editVariantEvent.emit({
        subProductId,
        variantId,
        unsavedProduct: this.productsFormService.getDraft(),
      });
    } else {
      this.productsFormService.markAllAsTouched();
    }
  }

  cloneProduct() {
    const product = this.entity as Product;
    this.cloneProductEvent.emit(product);
  }

  get urlsControl(): UntypedFormControl {
    return this.form.get('imageUrls') as UntypedFormControl;
  }
}
