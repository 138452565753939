<fo-toolbar [title]="entity ? 'editMachine' : 'newMachine'">
  <ng-container rightElements>
    <fo-action-buttons
      [actionButtons]="actions"
      (actionEvent)="onActionEvent($event)"
    ></fo-action-buttons>
  </ng-container>
</fo-toolbar>

<ng-container *ngIf="machineFormService.form$ | async as machineForm">
  <form
    #ngForm="ngForm"
    [@inOut]="'in'"
    [formGroup]="machineForm"
    (ngSubmit)="onSubmit()"
  >
    <br /><br />
    <div class="form-row">
      <div class="col-md-8">
        <crm-form-template
          [formGroup]="machineForm"
          [formFields]="formFields"
        ></crm-form-template>
      </div>
      <div class="col-md-4"></div>
    </div>
    <footer></footer>
  </form>
</ng-container>
